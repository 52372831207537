import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
  faDesktop,
  faMobile,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";

import React from "react";

const LogRoomUser = (props) => {
  const getDevice = (id) => {
    if (parseInt(id) === 1 || parseInt(id) === 2) {
      return <FontAwesomeIcon icon={faMobile} />;
    } else {
      return <FontAwesomeIcon icon={faDesktop} />;
    }
  };
  const calculateTimeInRoom = (item) => {
    if (item.extraData.timeInRoom) {
      let timeIn = (item.extraData.timeInRoom / 60).toFixed(2);

      if (item.extraData.timeInRoom > 60) {
      }
      return (
        <React.Fragment>
          <br></br>
          <span className="verySmallText">
            Time in room:{" "}
            <b
              className={classNames("verySmallText", {
                "text-danger": timeIn < 1,
                "text-warning": timeIn > 10,
                "text-info": timeIn > 1 && timeIn < 10,
              })}
            >
              {timeIn}
            </b>
          </span>
        </React.Fragment>
      );
    }
  };

  const getUserMode = (tipo) => {
    switch (tipo) {
      case 326:
      case 376:
        return "Anonymous";
      case 300:
      case 350:
        return "Registered";
      case 500:
      case 401:
        return "Broker";
      default:
        return `Mode ${tipo}`;
    }
  };
  return (
    <div>
      <p>{props.item.msg}</p>
      <p className="small ">
        <span
          className="colorNick handed"
          onClick={(e) => {
            props.setNick(props.item.extraData.nick);
          }}
        >
          {" "}
          {props.item.extraData.nick}
        </span>
        {props.item.extraData.tipo && (
          <span className="ml-2">({props.item.extraData.tipo})</span>
        )}
        {props.item.extraData.tipo >= 300 &&
          props.item.extraData.tipo <= 500 && (
            <span className="ml-2 userMode">
              {getUserMode(props.item.extraData.tipo)}
            </span>
          )}
      </p>
      {props.item.msg.toLowerCase() === "user leaves room" && (
        <p>
          {props.item.extraData.disconnectReason && (
            <span className="verySmallText">
              Disconnect reason: <b>{props.item.extraData.disconnectReason}</b>
            </span>
          )}
          {calculateTimeInRoom(props.item)}
        </p>
      )}
      {props.item.extraData.ip && (
        <p className="verySmallText">
          Ip: <b>{props.item.extraData.ip}</b>
          <br></br>
        </p>
      )}
      {props.item.extraData.device === 0 && (
        <FontAwesomeIcon icon={faDesktop} />
      )}
      {props.item.extraData.device === 1 && <FontAwesomeIcon icon={faMobile} />}
      {props.item.extraData.device === 2 && <FontAwesomeIcon icon={faTablet} />}
      {props.item.extraData.ops && (
        <span className="ml-2 small">
          <b>{props.item.extraData.ops}</b>
        </span>
      )}

      {props.item.extraData.tipo < 300 && (
        <span className="ml-2 small">vce</span>
      )}
    </div>
  );
};

export default LogRoomUser;
