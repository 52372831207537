import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobile } from "@fortawesome/free-solid-svg-icons";
import LogRoomUser from "../logRoomUser";
import Vip from "../../icons/vip";

const LogsEvent = (props) => {
  //console.log("LogsEvent", props.item);
  const getDevice = (id) => {
    if (parseInt(id) === 1 || parseInt(id) === 2) {
      return <FontAwesomeIcon icon={faMobile} />;
    } else {
      return <FontAwesomeIcon icon={faDesktop} />;
    }
  };
  if (!props.item.msg && props.item.message) {
    props.item.msg = props.item.message;
  }

  if (typeof props.item.msg !== "string") return <div></div>;
  switch (props.item.msg.toLowerCase()) {
    case "user enters room":
    case "user leaves room":
    case "free user enters room":
    case "free user leaves room":
    case "user trying to enter room":
    case "js room - playing user cam":
    case "js room - trying to play user cam":
      return <LogRoomUser item={props.item} setNick={props.setNick} />;
    case "toy connected":
    case "app kiiroo offline<br>toy kiiroo disconnected":
    case "get auth lovense":
    case "connect lovense":
    case "disconnect lovense":
    case "lovense activated":
    case "lovense deactivated":
    case "lovense endpoint":
    case "toy not connected":
    case "error checking kiiroo status":
    case "app and toy kiiroo connected":
      if (
        props.item.evt.toLowerCase() === "toys - lovense" ||
        props.item.msg.toLowerCase() === "lovense endpoint"
      ) {
        return (
          <p className="small">
            {props.item.msg}
            <br></br> <img src="/images/lovense.png"></img>
          </p>
        );
      } else if (
        props.item.evt.toLowerCase() === "toys - kiiroo" ||
        props.item.msg.toLowerCase() === "error checking kiiroo status"
      ) {
        return (
          <p className="small">
            {props.item.msg}
            <br></br>{" "}
            <img
              src="/images/feelconnect.png"
              width="32"
              alt="feelconnect"
            ></img>
          </p>
        );
      } else {
        return <p className="small">{props.item.msg}</p>;
      }

    case "should update preferred payment method?":
      return <p className="small">{props.item.msg}</p>;
    case "ws - disconnect":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>
              Reason: <b> {props.item.extraData.e} </b>
            </span>
          </p>
        </div>
      );

    case "room mode changed":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            {props.item.extraData.currentMode && (
              <span>
                From <b> {props.item.extraData.currentMode} </b>
              </span>
            )}
            <span>
              to
              <b> {props.item.extraData.newMode}</b>
            </span>
          </p>
        </div>
      );

    case "video started":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>
              Manifest: <b>{props.item.extraData.manifest}</b>
            </span>
          </p>
          <p className="small">
            <span>
              Streaming profile: <b>{props.item.extraData.name} </b>
            </span>
            <span className="">
              - {props.item.extraData.videoW} x {props.item.extraData.videoH} -{" "}
              {props.item.extraData.framerate} fps{" "}
            </span>
            - Quality: {props.item.extraData.qual}
            {props.item.extraData.bandw > 0 && (
              <span className="ml-2">
                BandWitdh: {props.item.extraData.bandw}
              </span>
            )}
          </p>
        </div>
      );
    case "setbroadcastforecast":
      return (
        <div>
          <p className="small">
            Broadcast forecast stored <b>{props.item.extraData.model_nick}</b>
            <br></br>
            {props.item.id}
          </p>
        </div>
      );
    case "generatelivelytoken":
      return (
        <div className="small">
          <p>{props.item.msg}</p>
          <p className="small">
            Token: <b>{props.item.extraData.json.token}</b>
          </p>
        </div>
      );

    case "room opened v.0.1.0":
    case "room opened":
    case "room opened new broadcaster":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            {getDevice(props.item.extraData.device)}
            <span className="ml-2">{props.item.extraData.os}</span>
            <span className="ml-2">- {props.item.browser}</span>
          </p>
          <p className="small">
            Lively ID: <b>{props.item.extraData.livelyId}</b>
          </p>
          <p className="small">
            {props.item.extraData.broadcastCity} -{" "}
            <b>{props.item.extraData.broadcastCountry}</b>
          </p>
          <p className="small">
            Estudio <b>{props.item.extraData.estudio}</b>
            <br></br>
            {props.item.extraData.vip === 1 && (
              <span className="ml-1 modeAllowed">VIP</span>
            )}
            {props.item.extraData.vip === 0 && (
              <span className="ml-1 modeNotAllowed">VIP</span>
            )}
            {props.item.extraData.freechat === 1 && (
              <span className="ml-1 modeAllowed">FREE</span>
            )}
            {props.item.extraData.freechat === 0 && (
              <span className="ml-1 modeNotAllowed">FREE</span>
            )}
            {props.item.extraData.private === 1 && (
              <span className="ml-1 modeAllowed">GROUP</span>
            )}
            {props.item.extraData.private === 0 && (
              <span className="ml-1 modeNotAllowed">GROUP</span>
            )}
          </p>
          <p className="small">
            {props.item.extraData.videoDevice && (
              <span>{props.item.extraData?.videoDevice?.label}</span>
            )}
            {props.item.extraData.audioDevice && (
              <span>
                <br></br>
                {props.item.extraData?.audioDevice?.label}
              </span>
            )}
          </p>
          <p className="small">
            Version: <b>{props.item.extraData.version}</b>
          </p>
        </div>
      );
    case "checking devices: camera ok":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>{props.item.extraData?.videoDevice?.label}</span>
          </p>
        </div>
      );
    case "checking devices: microphone ok":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>{props.item.extraData?.audioDevice?.label}</span>
          </p>
        </div>
      );
    case "checking devices: camera changed":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>{props.item.extraData.videoDevice}</span>
          </p>
        </div>
      );
    case "checking devices: micro changed":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>{props.item.extraData.audioDevice}</span>
          </p>
        </div>
      );
    case "start locution":
    case "start call":
    case "stop call":
      return (
        <div className="small">
          <p>
            PRS <u>Simulation</u>
            <br></br>
            <b>{props.item.msg}</b>
          </p>
        </div>
      );
    case "doi simulation":
    case "soi simulation":
      return (
        <div className="small">
          <p>
            SMS <u>Simulation</u>
            <br></br>
            <b>{props.item.msg}</b>
          </p>
        </div>
      );
    case "sendtip":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>
              <b>{props.item.extraData.amount}</b> cr to{" "}
              <b> {props.item.extraData.model_nick}</b>
            </span>
          </p>
        </div>
      );
    case "performer valoration ok - online performer":
      return (
        <div>
          <p className="small">
            Performer valoration - <b>{props.item.extraData.model_id}</b>
          </p>
          <p className="small">
            <span>{props.item.extraData.vote} points</span>
          </p>
        </div>
      );
    case "performer valoration failed: session already valorated":
      return (
        <div>
          <p className="small">
            Performer valoration failed: session already valorated -{" "}
            <b>{props.item.extraData.model_id}</b>
          </p>
        </div>
      );
    case "setting devices":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <span>{props.item.extraData.videoDevice}</span>
            <br></br>
            <span>{props.item.extraData.audioDevice}</span>
          </p>
        </div>
      );
    case "starting video":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            Lively Token: <b>{props.item.extraData.livelyToken}</b>
            <br />
            Lively API: <b>{props.item.extraData.Api_uri}</b>
          </p>
        </div>
      );
    case "tip received":
      return (
        <div>
          <p>{props.item.msg}</p>
          <p className="small">
            <br />
            <b> {props.item.extraData.data.nick}</b>:{" "}
            {props.item.extraData.data.tipType} -{" "}
            {props.item.extraData.data.tip}
          </p>
        </div>
      );
    case "addfavourite":
    case "removefavourite":
      return (
        <div>
          <p className="small">{props.item.msg} </p>
          {props.item.extraData && (
            <p className="small">
              <b>{props.item.extraData.model_nick}</b>
            </p>
          )}
        </div>
      );
    case "updateperformercache - memcached":
      return (
        <div>
          <p className="small">
            {props.item.msg} - <b> {props.item.id}</b>
          </p>
        </div>
      );
    case "deleteperformercache":
      return (
        <div>
          <p className="small">
            Delete performer cache - <b> {props.item.id}</b>
          </p>
        </div>
      );
    case "requestvip":
      return (
        <div>
          <p className="small">
            {props.item.msg} - <b> {props.item.extraData.model_nick}</b>
          </p>
        </div>
      );
    case "vip request accepted":
    case "vip request rejected":
      return (
        <div>
          <p className="small">
            {props.item.msg} - <b> {props.item.extraData.nick}</b>
          </p>
        </div>
      );
    case "user field updated":
      return (
        <div>
          <p className="small">User updated</p>
          {props.item.extraData && (
            <p className="small">
              Field: <b> {props.item.extraData.field}</b>
              <br></br>
              New Value: <b>{props.item.extraData.value}</b>
            </p>
          )}
          {props.item.msg.toLowerCase() === "user register (google)" && (
            <img src="/images/logo-Google.png" width="32" alt="google"></img>
          )}

          {props.item.msg.toLowerCase() === "user register (twitter)" && (
            <img src="/images/twitter.png" width="20" alt="twitter"></img>
          )}
        </div>
      );
    case "sentip":
      return (
        <div>
          <p className="small">
            {props.item.msg} -
            {props.item.extraData && <b>{props.item.extraData.model_id}</b>}
          </p>
        </div>
      );
    case "user register":
    case "user register (google)":
    case "user register (callbacks)":
      return (
        <div>
          <p className="small">User Register</p>
          {props.item.extraData && (
            <p className="small">
              Nick: <b> {props.item.extraData.nick}</b>
              <br></br>
              Afno: {props.item.extraData.afno}
            </p>
          )}
          {props.item.msg.toLowerCase().includes("google") && (
            <img src="/images/logo-Google.png" width="32" alt="google"></img>
          )}

          {props.item.msg.toLowerCase() === "user register (twitter)" && (
            <img src="/images/twitter.png" width="20" alt="twitter"></img>
          )}
          {props.item.msg.toLowerCase() === "user register (callbacks)" && (
            <p className="small">
              <b>Callbacks</b> - autoregister
            </p>
          )}
        </div>
      );
    case "login ok":
    case "user login (google)":
      if (props.item.evt.toLowerCase() === "prs") {
        return (
          <div>
            <p>
              <b>Login OK</b>
            </p>
            {props.item.extraData && (
              <p className="small">
                Nick: Anonymous ({props.item.extraData.id})<br></br>
                Token: <b>{props.item.extraData.token}</b>
                <br></br>
                Session: <b>{props.item.extraData.session}</b>
                <br></br>
                Number: <b>{props.item.extraData.llamante}</b>
              </p>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <p>Login OK</p>
            {props.item.extraData && props.item.extraData.response && (
              <p className="small">
                Nick: <b>{props.item.extraData.response.nick}</b> (
                {props.item.extraData.response.id})<br></br>
                Token: <b>{props.item.extraData.response.token}</b>
                <br></br>
                Credits: <b>{props.item.extraData.response.credits}</b>
              </p>
            )}
            {props.item.extraData && !props.item.extraData.response && (
              <p className="small">
                Nick: <b>{props.item.extraData.nick}</b> (
                {props.item.extraData.id})<br></br>
                Token: <b>{props.item.extraData.token}</b>
                <br></br>
                Credits: <b>{props.item.extraData.credits}</b>
              </p>
            )}
            {props.item.msg.toLowerCase().includes("google") && (
              <img src="/images/logo-Google.png" width="32" alt="google"></img>
            )}

            {props.item.msg.toLowerCase().includes("twitter") && (
              <img src="/images/twitter.png" width="20" alt="twitter"></img>
            )}
          </div>
        );
      }
    case "trying to play user cam":
      return (
        <div>
          <p>
            {props.item.msg} - <b> {props.item.extraData.nick}</b>
          </p>
        </div>
      );

    case "get premium phone number":
      return (
        <div>
          <p className="small">
            <b>{props.item.msg}</b>:
            {props.item.evt.toLowerCase() === "get premium phone number" ||
              (props.item.evt.toLowerCase().includes("prs") &&
                props.item.extraData && (
                  <span>{props.item.extraData.numero}</span>
                ))}
          </p>

          {props.item.evt.toLowerCase().includes("dtic") ||
            (props.item.extraData.provider.toLowerCase().includes("dtic") && (
              <p>
                <img src="/images/dtic.png" width="40" alt="dtic"></img>
              </p>
            ))}
        </div>
      );
    case "call started":
    case "call stopped":
    case "jwt decoded":
      return (
        <div>
          <p className="small">
            <b>{props.item.msg}</b>
            {props.item.extraData && (
              <>
                {" "}
                <br></br>
                <span className="small">{props.item.extraData.llamante}</span>
                <br></br>
                <span className="small">
                  Number: <b>{props.item.extraData.numero}</b>
                </span>
                {props.item.extraData.token && (
                  <>
                    <br></br>
                    <span className="small">
                      Token: <b>{props.item.extraData.token}</b>
                    </span>
                  </>
                )}
              </>
            )}
          </p>

          {props.item.extraData &&
            props.item.extraData.provider &&
            props.item.extraData.provider.toLowerCase() === "dtic" && (
              <p>
                <img src="/images/dtic.png" width="40" alt="dtic"></img>
              </p>
            )}
        </div>
      );
    case "soi":
      return (
        <>
          <p className="small">SMS SOI</p>
          {props.item.extraData &&
            props.item.extraData.provider &&
            props.item.extraData.provider.toLowerCase() === "dtic" && (
              <p>
                <img src="/images/dtic.png" width="40" alt="dtic"></img>
              </p>
            )}
        </>
      );
    case "double optin":
      return (
        <>
          <p className="small">SMS DOI</p>
          {props.item.extraData &&
            props.item.extraData.provider &&
            props.item.extraData.provider.toLowerCase() === "dtic" && (
              <p>
                <img src="/images/dtic.png" width="40" alt="dtic"></img>
              </p>
            )}
        </>
      );
    case "ticket not found":
    case "ticket used":
      return (
        <>
          <p className="small">SMS DOI</p>
          <p className="small">
            Error: <b>{props.item.msg}</b>
          </p>
          {props.item.extraData && props.item.extraData.ticket && (
            <p className="small">
              Ticket: <b>{props.item.extraData.ticket}</b>
            </p>
          )}
          {props.item.extraData &&
            props.item.extraData.provider &&
            props.item.extraData.provider.toLowerCase() === "dtic" && (
              <p>
                <img src="/images/dtic.png" width="40" alt="dtic"></img>
              </p>
            )}
        </>
      );
    case "message_id already exists":
    case "temporal id not found":
      return (
        <>
          <p className="small">SMS SOI</p>
          <p className="small">
            Error: <b>{props.item.msg}</b>
          </p>
          {props.item.extraData && props.item.extraData.ticket && (
            <p className="small">
              Ticket: <b>{props.item.extraData.ticket}</b>
            </p>
          )}
          {props.item.extraData &&
            props.item.extraData.provider &&
            props.item.extraData.provider.toLowerCase() === "dtic" && (
              <p>
                <img src="/images/dtic.png" width="40" alt="dtic"></img>
              </p>
            )}
        </>
      );

    default:
      if (
        props.item.msg.toLowerCase().includes("cg") ||
        props.item.msg.toLowerCase().includes("commerce gate")
      ) {
        return (
          <p className="small">
            {props.item.msg} <br></br>
            <img src="/images/cg.png" alt="commercegate"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("epoch")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/epoch.png" alt="epoch"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("adwords")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/adwords.png" alt="adwords"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("voluum")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/voluum.png" alt="voluum" height="50"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("segpay")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/segpay.png" alt="segpay"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("directa24")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/d24.png" alt="D24"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("inespay")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/inespay.png" alt="Inespay"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("rocketfuel")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/rocketfuel.png" alt="Rocketfuel"></img>
          </p>
        );
      } else if (props.item.msg.toLowerCase().includes("smartpay")) {
        return (
          <p className="small">
            {props.item.msg} <br></br>{" "}
            <img src="/images/smartpay.svg" alt="smartpay" width="80"></img>
          </p>
        );
      } else {
        return <p className="small">{props.item.msg}</p>;
      }
  }
};

export default LogsEvent;
