import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// API Service
import AlertService from "../../../services/alerts-service";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Button, Modal, Alert } from "react-bootstrap";

const CreateAlertModal = (props) => {
  const [name, setName] = useState(props.data.name ? props.data.name : "");
  const [id] = useState(props.data.id ? props.data.id : null);
  const [description, setDescription] = useState(
    props.data.name ? props.data.description : ""
  );

  const [action, setAction] = useState(
    props.data.action ? props.data.action : null
  );

  //calculate days, hours, min
  let d = 0; //defaults 0 days
  let h = 0; //defaults 0 hours
  let m = 30; //defaults 30 minutes

  if (props.data.dispatch) {
    d = Math.floor(props.data.dispatch / 1440); // 60*24
    h = Math.floor((props.data.dispatch - d * 1440) / 60);
    m = Math.round(props.data.dispatch % 60);
  }

  const [dispatch, setDispatch] = useState(
    props.data.dispatch ? props.data.dispatch : 30
  );
  const [message, setMessage] = useState(
    props.data.message ? props.data.message : ""
  );
  const [status, setStatus] = useState(
    props.data.status === 0 ? props.data.status : 1
  );

  const [type, setType] = useState(
    props.data.type ? props.data.type : "global"
  );

  const [variantAlert, setVariantAlert] = useState(false);
  const [noActions, setNoActions] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(false);
  const [availableActions, setAvailableActions] = useState([]);
  const [hours, setHours] = useState(h);
  const [days, setDays] = useState(d);
  const [minutes, setMinutes] = useState(m);

  // on component mount
  useEffect(() => {
    AlertService.getActions(props.data.action).then(([res, code]) => {
      console.log(res, code);
      if (code === 200) {
        if (res.length === 0) setNoActions(true);
        setAvailableActions(res);
      } else {
        setNoActions(true);
      }
    });
  }, []);

  useEffect(() => {
    if (availableActions.length === 0) return;
    if (props.data.action) {
      setAction(availableActions[availableActions.indexOf(props.data.action)]);
    } else {
      setAction(availableActions[0]);
    }
  }, [props, availableActions]);

  useEffect(() => {
    const dis =
      parseInt(days) * 1440 + parseInt(hours) * 60 + parseInt(minutes);
    if (dis > 0) {
      setDispatch(dis);
    } else {
      toast.error("Dispatch time can't be 0");
    }
  }, [days, hours, minutes, setDispatch]);

  const handleSaveAlert = async () => {
    if (id) {
      if (dispatch < 1) {
        toast.error("Dispatch time can't be 0");
        return;
      }
      const [response, code] = await AlertService.update({
        name,
        description,
        action,
        dispatch,
        id,
        type,
        status,
        message,
      });
      handleResponse(response, code);
    } else {
      const [response, code] = await AlertService.set({
        name,
        description,
        action,
        dispatch,
        type,
        status,
        message,
      });
      handleResponse(response, code);
    }
  };

  const handleResponse = (response, code) => {
    if (code === 200) {
      props.handleClose();
    } else {
      setShowAlert(true);
      setTextAlert(response.error);
      setVariantAlert("danger");
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "action":
        setAction(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "dispatch":
        setDispatch(value);
        break;
      case "type":
        setType(value);
        break;
      case "message":
        setMessage(value);
        break;
      case "hours":
        setHours(value);
        break;
      case "minutes":
        setMinutes(value);
        break;
      case "days":
        setDays(value);

        break;
      case "status":
        setStatus(e.target.checked ? 1 : 0);
        break;
      //no default
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false}>
      {showAlert && <Alert variant={variantAlert}>{textAlert}</Alert>}
      <Modal.Header closeButton>
        <Modal.Title>
          {id && <span>Update alert</span>}
          {!id && <span>Create alert</span>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {noActions && (
          <div className="alert alert-danger" role="alert">
            No available Actions
          </div>
        )}
        {action && availableActions.length > 0 && (
          <form>
            <div className="form-inline mb-2">
              <div className="col-3">Alert name*</div>
              <div className="col text-left">
                <input
                  className="form-control"
                  name="name"
                  type="text"
                  placeholder="name"
                  onChange={handleOnChange}
                  value={name}
                />
              </div>
            </div>
            <div className="form-inline mb-2">
              <div className="col-3">Action*</div>
              <div className="col text-left">
                <select
                  className="form-control"
                  name="action"
                  id="action"
                  onChange={handleOnChange}
                  defaultValue={action}
                >
                  <option key="none" value="none">
                    None
                  </option>
                  {availableActions.map((number) => {
                    return (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-inline mb-2">
              <div className="col-3">Dispatch*</div>
              <div className="col text-left">
                <select
                  className="form-control"
                  name="days"
                  id="days"
                  onChange={handleOnChange}
                  defaultValue={days}
                >
                  {Array.apply(0, Array(32)).map(function (i, number) {
                    return (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    );
                  })}
                </select>
                <span className="ml-2">Days</span>
              </div>
              <div className="col text-left">
                <select
                  className="form-control"
                  name="hours"
                  id="hours"
                  onChange={handleOnChange}
                  defaultValue={hours}
                >
                  {Array.apply(0, Array(24)).map(function (i, number) {
                    return (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    );
                  })}
                </select>
                <span className="ml-2">Hours</span>
              </div>
              <div className="col text-left">
                <select
                  className="form-control"
                  name="minutes"
                  id="minutes"
                  onChange={handleOnChange}
                  defaultValue={minutes}
                >
                  {Array.apply(0, Array(60)).map(function (i, number) {
                    return (
                      <option key={number} value={number}>
                        {number}
                        {i}
                      </option>
                    );
                  })}
                </select>
                <span className="ml-2"> Min</span>
              </div>
            </div>
            <div className="form-inline mb-2">
              <div className="col-3">Type*</div>
              <div className="col text-left">
                <select
                  className="form-control"
                  name="type"
                  id="type"
                  onChange={handleOnChange}
                  defaultValue={type}
                >
                  {props.types.length > 0 &&
                    props.types.map((number) => {
                      return (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="form-inline mb-2">
              <div className="col-3">Message*</div>
              <div className="col text-left">
                <textarea
                  className="form-control"
                  rows={3}
                  name="message"
                  placeholder="Content of alert message"
                  onChange={handleOnChange}
                  value={message}
                ></textarea>
              </div>
            </div>
            <div className="form-inline mb-2">
              <div className="col-3">Description</div>
              <div className="col text-left">
                <textarea
                  rows={3}
                  className="form-control"
                  name="description"
                  placeholder="Internal alert description"
                  onChange={handleOnChange}
                  value={description}
                ></textarea>
              </div>
            </div>
            <div className="form-inline mb-2">
              <div className="col-3">Status</div>
              <div className="col text-left">
                <input
                  className="form-control"
                  name="status"
                  type="checkbox"
                  label="Active"
                  value="0"
                  onChange={handleOnChange}
                  defaultChecked={status}
                />
              </div>
            </div>
          </form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Cerrar
        </Button>
        {id && (
          <Button variant="success" onClick={(e) => handleSaveAlert()}>
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            <span>Update</span>
          </Button>
        )}
        {!noActions && id === null && (
          <Button variant="success" onClick={(e) => handleSaveAlert()}>
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            <span>Create</span>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(CreateAlertModal);
