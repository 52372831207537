const LogsUser = (props) => {
  //console.log("LogsUser", props);
  return (
    <div>
      <p
        className="handed small"
        onClick={(e) => {
          props.setUs(props.item.us);
        }}
      >
        {props.item.us}
        {props.item.id_operadora && (
          <span className="ml-2 small">({props.item.id_operadora})</span>
        )}
      </p>

      {props.item.nick && (
        <p
          className="small handed colorNick"
          onClick={(e) => {
            props.setNick(props.item.nick);
          }}
        >
          {props.item.nick}
        </p>
      )}

      {props.item.roomName && (
        <p
          className="small handed colorRoomName"
          onClick={(e) => {
            props.setRoomname(props.item.roomName);
          }}
        >
          {props.item.roomName}
        </p>
      )}
      {props.item.id && <p className="small ">{props.item.id}</p>}
    </div>
  );
};

export default LogsUser;
