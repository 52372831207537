import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../../libs/isAuth";
//Services
import UserService from "../../../services/user-service";

import { Breadcrumb, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPlus } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from "../../../components/layout";
import Loading from "../../../components/loading";
import Table from "./table.js";
//style
import "./index.scss";
import CreateUserModal from "./createUserModal";
import UpdateUserModal from "./updateUserModal";

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      update_data: {},
      loading: false,
      showModal: false,
      showUpdateModal: false,
    };
  }
  componentDidMount() {
    this.getList();
  }

  getList() {
    this.setState({
      loading: true,
    });
    UserService.list()
      .then(([response]) => {
        this.setState({
          data: response.results,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.message === "Unauthorized IP, or Origin") {
          this.setState({
            showAlert: true,
            alertText: error.message,
            error: true,
            alertSeverity: "info", // error, warning, info, success
          });
        }
        this.setState({
          loading: false,
        });
      });
  }

  handleShowModal = () => {
    this.setState({
      showModal: true,
      showUpdateModal: false,
    });
  };

  handleClose = () => {
    this.setState({ showModal: false, showUpdateModal: false });
    this.getList();
  };

  updateUser = (data) => {
    this.setState({
      showModal: false,
      showUpdateModal: true,
      update_data: data,
    });
  };

  deleteUser = (id) => {
    if (window.confirm("¿Seguro que quiere eliminar usuario?")) {
      this.setState({ loading: true });
      UserService.deleteUser(id)
        .then(([response]) => {
          this.setState({
            loading: false,
          });
          this.getList();
        })
        .catch((error) => {
          if (error.message === "Unauthorized IP, or Origin") {
            this.setState({
              showAlert: true,
              alertText: error.message,
              error: true,
              alertSeverity: "info", // error, warning, info, success
            });
          }
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { showModal, showUpdateModal } = this.state;
    return (
      <Layout>
        <Breadcrumb>
          <NavLink exact={true} activeClassName="active" to="/" role="button">
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            Users
          </NavLink>
        </Breadcrumb>
        {this.state.loading && <Loading />}
        <Row className="justify-content-md-center pb-3">
          <Button
            variant="primary"
            className="text-center"
            onClick={this.handleShowModal}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Crear
          </Button>
        </Row>

        {showModal && (
          <CreateUserModal
            show={true}
            handleClose={this.handleClose}
            handleSaveUser={this.handleSaveUser}
          />
        )}

        {showUpdateModal && (
          <UpdateUserModal
            show={true}
            handleClose={this.handleClose}
            handleSaveUser={this.handleSaveUser}
            data={this.state.update_data}
          />
        )}

        <Table
          data={this.state.data}
          deleteUser={this.deleteUser}
          updateUser={this.updateUser}
        />
      </Layout>
    );
  }
}

export default withRouter(isAuth()(UserManagement));
