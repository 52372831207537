import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Pagination from "../../components/pagination";
import Launch from "../../components/icons/launch";
import AlertToolTip from "../../components/alertToolTip";
import AlertStatus from "./alertStatus";
import AlertService from "../../services/alerts-service";
import Threepoints from "../../components/icons/threepoints";
//style
import "./index.scss";

const AlertTable = (props) => {
  const [category, setCategory] = useState("global");
  const [alertInfo, setAlertInfo] = useState(null);
  const types = ["all", "opened", "closed"];
  const categories = ["critical", "global", "olecams", "vce", "mow"];

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    if (name === "type") {
      props.handleFilters(value);
    } else if (name === "category") {
      setCategory(value);
    }
  };

  const dispatchAlert = async () => {
    const [response, code] = await AlertService.dispatch(category);
    if (code === 200) {
      toast.success("Alert dispatched");
    } else {
      toast.error(`Dispatch failed - ${response.error}`);
    }
  };

  const dispatcheddetail = async (id) => {
    const [response, code] = await AlertService.dispatcheddetail(id);
    if (code === 200) {
      setAlertInfo(response);
    } else {
      toast.error(`Error reading alert info - ${response.error}`);
    }
  };
  const closeToolTip = () => {
    setAlertInfo(null);
  };

  return (
    <React.Fragment>
      {props.enabled && (
        <div className="row mb-4">
          <div className="col-6 col-md-4">
            <div className="form-group">
              <label>Alert Status</label>
              <select
                className="form-control"
                name="type"
                id="type"
                onChange={handleOnChange}
              >
                {types.length > 0 &&
                  types.map((number) => {
                    if (props.filters.type === number) {
                      return (
                        <option key={number} defaultValue={number}>
                          {number}
                        </option>
                      );
                    } else {
                      return (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
          </div>

          <div className="col-4 col-md-4">
            <div className="form-group">
              <label>Dispatch alerts</label>
              <select
                className="form-control"
                name="category"
                id="category"
                onChange={handleOnChange}
              >
                {categories.length > 0 &&
                  categories.map((number) => {
                    if (props.filters.type === number) {
                      return (
                        <option key={number} defaultValue={number}>
                          {number}
                        </option>
                      );
                    } else {
                      return (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
          </div>
          <div className="col-2 mt-4 handed" onClick={dispatchAlert}>
            <Launch />
          </div>
        </div>
      )}
      {alertInfo && (
        <AlertToolTip data={alertInfo} show={true} handleClose={closeToolTip} />
      )}
      <table
        className={classNames(
          "table table-bordered table-hover table-striped table-sm",
          {
            "table-alerts-disabled": !props.enabled,
          }
        )}
      >
        <thead>
          <tr className="text-left">
            <th>Alert</th>
            <th>Open date</th>
            <th>Close date</th>
            <th className="">Closed</th>
            <th>Duration</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.data.data.length > 0 && (
            <>
              {props.data.data.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={classNames("tdAlerts", {
                      "table-danger": item.status === 0,
                    })}
                  >
                    <td className="text-left">
                      <small>{item.alert}</small>
                    </td>
                    <td className="text-left">
                      <small>{item.open_date}</small>
                    </td>
                    <td className="text-left font-weight-bold">
                      {item.status === 1 && <small>{item.close_date}</small>}
                      {item.status === 0 && (
                        <small>
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </small>
                      )}
                    </td>

                    <td className="text-center">
                      <AlertStatus
                        status={item.status}
                        click={props.closeAlert}
                        id={item._id}
                      />
                    </td>
                    <td className="text-left">
                      {item.status === 0 && <small>-</small>}
                      {item.status === 1 && <span>{item.duration}</span>}
                    </td>
                    <td
                      className="text-center handed"
                      onClick={(e) => dispatcheddetail(item._id)}
                    >
                      <Threepoints />
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      <Pagination
        handleChangeNumberOfColum={props.handleChangeNumberOfColum}
        pagination={props.data.pagination}
        prevPage={props.prevPage}
        nextPage={props.nextPage}
      />
    </React.Fragment>
  );
};

export default withRouter(AlertTable);
