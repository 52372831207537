import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Toast } from "react-bootstrap";

import isAuth from "../../../libs/isAuth";
import Layout from "../../../components/layout";
import Loading from "../../../components/loading";

import BrokersService from "../../../services/ot-brokers-service";

class Brokers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showAlert: false,
      textAlert: "",
      variantAlert: "success",
    };
  }

  generateBrokerFiles = () => {
    this.setState({ loading: true });

    BrokersService.generateBrokerFiles()
      .then((response) => {
        if (response.status === "Accepted") {
          this.setState({
            variantAlert: "success",
            textAlert: "Ficheros de brokers generados con éxito!",
            showAlert: true,
          });
        } else {
          this.setState({
            variantAlert: "danger",
            textAlert: "Ficheros de brokers no generados",
            showAlert: true,
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };
  render() {
    return (
      <Layout>
        <nav aria-label="breadcrumb">
          <NavLink exact={true} activeClassName="active" to="/" role="button">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Gestión de Brokers
              </li>
            </ol>
          </NavLink>
        </nav>
        {this.state.loading && <Loading />}

        <div className="row mb-4">
          <div className="col auto">
            <Toast
              className={"bg-" + this.state.variantAlert}
              onClose={() => this.setState({ showAlert: false })}
              show={this.state.showAlert}
              delay={3000}
              animation
              autohide
            >
              <Toast.Header>
                <strong className="mr-auto">Brokers</strong>
              </Toast.Header>
              <Toast.Body className="text-white">
                {this.state.textAlert}
              </Toast.Body>
            </Toast>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <button
              className="btn btn-primary mr-2"
              onClick={this.generateBrokerFiles}
            >
              <FontAwesomeIcon icon={faCheck} className="mr-2" /> Regenerar
              ficheros datos_brokers.php y datos_brokers.json
            </button>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(isAuth()(Brokers));
