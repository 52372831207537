import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/login";
import Requestpwd from "./pages/requestpwd";
import Recovery from "./pages/recovery";
import Home from "./pages/home";
import Logs from "./pages/Logs";
import Alerts from "./pages/Alerts";
import LogDetail from "./pages/Logdetail";
import UserDetail from "./pages/userDetail";
import UsersManagement from "./pages/admin/user";
import AlertsManagement from "./pages/admin/alerts";
import BlackListIP from "./pages/admin/blacklistip";
import BlackListEmail from "./pages/admin/blacklistemail";
import Websockets from "./pages/admin/websockets";
import Payments from "./pages/admin/payments";
import DataBase from "./pages/admin/database";
import Cache from "./pages/admin/cache";
import Brokers from "./pages/admin/brokers";
import NotFound from "./pages/notFound";
import Documentation from "./pages/documentation";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/recovery/:token" component={Recovery} />
          <Route path="/requestpwd" component={Requestpwd} />
          <Route path="/home" component={Home} />
          <Route path="/logs/:log" component={Logs} exact />
          <Route path="/alerts" component={Alerts} />
          <Route path="/log" component={LogDetail} />
          <Route path="/documentation" component={Documentation} />
          <Route path="/user" component={UserDetail} />
          <Route path="/admin/user" component={UsersManagement} />
          <Route path="/admin/alerts" component={AlertsManagement} />
          <Route path="/admin/blacklistip" component={BlackListIP} />
          <Route path="/admin/blacklistemails" component={BlackListEmail} />
          <Route path="/admin/websockets" component={Websockets} />
          <Route path="/admin/payments" component={Payments} />
          <Route path="/admin/database" component={DataBase} />
          <Route path="/admin/cache" component={Cache} />
          <Route path="/admin/brokers" component={Brokers} />

          <Route component={NotFound} />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
