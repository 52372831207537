import React, { Component } from "react";
import { Redirect } from "react-router-dom";

//actions
//import { checkusertoken } from "../actions/user";
import UserService from "../services/user-service";
//components
import Loading from "../components/loading";
//libs
import { getAuth, removeAuth } from "./cookies";

const IsAuth = (props) => (WrappedComponent) => {
  return class IsAuth extends Component {
    constructor(props) {
      super(props);
      this.state = {
        auth: null,
      };
    }
    componentDidMount() {
      const auth = getAuth();
      if (auth) {
        UserService.me()
          .then(([response, code]) => {
            if (code !== 200) {
              removeAuth();
              this.setState({ auth: false });
              return <Redirect to="/login" />;
            } else {
              this.setState({ auth: true });
            }
          })
          .catch((error) => {
            removeAuth();
            return <Redirect to="/login" />;
          });
      } else {
        removeAuth();
        this.setState({ auth: false });
        return <Redirect to="/login" />;
      }
    }
    render() {
      if (this.state.auth === null) {
        return <Loading />;
      } else if (this.state.auth) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  };
};
export default IsAuth;
