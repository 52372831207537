import React from "react";
import { withRouter } from "react-router-dom";

import { Button, Table } from "react-bootstrap";

import UserStatus from "./UserStatus";
import UserLevel from "./UserLevel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

//style
import "./index.scss";

const UserTable = (props) => {
  return (
    <Table striped bordered hover responsive size="sm">
      <thead>
        <tr className="text-center">
          <th>Username</th>
          <th>Email</th>
          <th>Level</th>
          <th>Voluum access</th>
          <th>Active</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.data.length > 0 && (
          <>
            {props.data.map((item, index) => {
              return (
                <tr key={index} className={(index + 1) % 2 ? "impar" : "par"}>
                  <td className="text-center">
                    <small>{item.USERNAME}</small>
                  </td>
                  <td className="text-center">
                    <small>{item.EMAIL}</small>
                  </td>
                  <td className="text-center">
                    <UserLevel status={item.BB_LEVEL} />
                  </td>
                  <td className="text-center">
                    <UserStatus status={item.VOLUUM_ACCESS} />
                  </td>
                  <td className="text-center">
                    <UserStatus status={item.ACTIVE} />
                  </td>
                  <td className="text-center">
                    <small>
                      <Button
                        variant="info"
                        onClick={(e) => props.updateUser(item)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </small>
                  </td>
                  <td className="text-center">
                    <small>
                      <Button
                        variant="danger"
                        onClick={(e) => props.deleteUser(item.ID)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </small>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default withRouter(UserTable);
