import request from "./api-service";

function list() {
  return request({
    url: `/admin/blacklistemail`,
    method: "GET",
  });
}

function add(domain) {
  return request({
    url: `/admin/blacklistemail`,
    method: "POST",
    data: { domain },
  });
}

function check(domain) {
  return request({
    url: `/admin/blacklistemail/${domain}`,
    method: "GET",
  });
}

function deleteDomain(domain) {
  return request({
    url: `/admin/blacklistemail/${domain}`,
    method: "DELETE",
  });
}

const BlackListEmailService = {
  add,
  list,
  check,
  deleteDomain,
};

export default BlackListEmailService;
