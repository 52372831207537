import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import LogsService from "../../../services/logs-service";
import Loading from "../../../components/loading";
import { dateFormatForApi, zeroPad } from "../../../libs/methods";
import Pagination from "../../../components/pagination";
import Threepoints from "../../../components/icons/threepoints";
import Logstatus from "../../../components/logs/logstatus";
import LogsEvents from "../../../components/logs/logsevents";
import LogsUser from "../../../components/logs/loguser";
import LogsSection from "../../../components/logs/logssection";
import LogDate from "../../../components/logs/logdate";
const TableLogs = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [bucket, setBucket] = useState(null);
  const [roomname, setRoomname] = useState("");
  const [us, setUs] = useState("");
  const [free, setFree] = useState("");
  const [nick, setNick] = useState("");
  const [paginator, setPaginator] = useState({ page: 1, items: 100 });
  const [filters, setFilters] = useState({
    ip: "",
    us: "",
    roomName: "",
    nick: "",
  });
  const [dayStart, setDayStart] = useState();
  const [dayEnd, setDayEnd] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  const getLastDayMonth = (year, month) => {
    const today = new Date();
    if (
      zeroPad(today.getFullYear(), 4) === year &&
      zeroPad(today.getMonth() + 1, 2) === month
    ) {
      return today.getDate();
    } else {
      return new Date(year, month, 0).getDate();
    }
  };

  const copyFiltersToQueryString = () => {
    const params = new URLSearchParams();
    if (dayStart) params.append("dayStart", dayStart);
    if (dayEnd) params.append("dayEnd", dayEnd);
    if (year) params.append("year", year);
    if (month) params.append("month", month);
    if (bucket) params.append("bucket", bucket);
    Object.keys(filters).forEach((filter) => {
      if (filters[filter] == undefined) return;
      if (filters[filter] == "") return;
      params.append(filter, filters[filter]);
    });
    history.push({ search: params.toString() });
  };

  const copyFiltersFromQueryString = () => {
    const params = new URLSearchParams(history.location.search);
    for (const param of params.entries()) {
      const [key, value] = param;
      if (["ip", "us", "roomName", "nick"].includes(key)) {
        setFilters((current) => ({
          ...current,
          [key]: value,
        }));
      }
      if (key === "dayStart") setDayStart(value);
      if (key === "dayEnd") setDayEnd(value);
      if (key === "year") setYear(value);
      if (key === "month") setMonth(value);
      if (key === "bucket") setBucket(value);
    }
  };

  useEffect(() => {
    copyFiltersFromQueryString();
  }, []);

  useEffect(() => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        us,
      };
    });
  }, [us, setFilters]);

  useEffect(() => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        nick,
      };
    });
  }, [nick, setFilters]);

  useEffect(() => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        roomName: roomname,
      };
    });
  }, [roomname, setFilters]);

  useEffect(() => {
    let today = new Date();
    let currentMonth = today.getMonth() + 1;
    if (currentMonth < 10) {
      currentMonth = `0${currentMonth}`;
    }
    setBucket(`${props.bucket}_${currentMonth}_${today.getFullYear()}`);
    setYear(today.getFullYear());
    setMonth(currentMonth);
    setRoomname("");
    setNick("");
    setUs("");
  }, [props.bucket]);

  useEffect(() => {
    if (!bucket) return;
    const year = bucket.split("_").reverse()[0];
    const month = bucket.split("_").reverse()[1];
    setYear(year);
    setMonth(month);
    setDayStart(1);
    setDayEnd(getLastDayMonth(year, month));
    setPaginator((prevPaginator) => {
      return {
        ...prevPaginator,
        page: 1,
      };
    });
  }, [bucket]);

  useEffect(() => {
    getLogs();
    // }, [bucket, dayStart, dayEnd, paginator]);
  }, [bucket, paginator]);

  const getLogs = useCallback(() => {
    if (!dayStart || !dayEnd || !bucket) return;
    copyFiltersToQueryString();
    setLoading(true);
    LogsService.getLogs(
      bucket,
      filters,
      dateFormatForApi(`${year}-${month}-${dayStart}`),
      dateFormatForApi(`${year}-${month}-${dayEnd}`),
      paginator
    )
      .then(([res, code]) => {
        console.log("getLogs then", paginator, res, code, filters);
        if (code === 200) {
          setTableData(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bucket, filters, paginator, dayStart, dayEnd]);

  const handleOnChange = (e) => {
    let { value, name } = e.target;
    if (name === "us") {
      setUs(value);
    } else if (name === "roomName") {
      setRoomname(value);
    } else if (name === "nick") {
      setNick(value);
    } else if (name === "free") {
      setFree(value);
      try {
        const freeValues = value.split(":");
        name = freeValues[0];
        value = freeValues[1];
      } catch (e) {
        console.error(e);
        name = null;
        value = null;
      }
    }
    if (name && value) {
      console.log(name, value);
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          [name]: value,
        };
      });
    }
  };
  const handleChangeNumberOfColum = (items) => {
    setPaginator((prevPaginator) => {
      return {
        ...prevPaginator,
        items,
      };
    });
  };

  const nextPage = (page) => {
    setPaginator((prevPaginator) => {
      return {
        ...prevPaginator,
        page,
      };
    });
  };

  const prevPage = (page) => {
    setPaginator((prevPaginator) => {
      return {
        ...prevPaginator,
        page,
      };
    });
  };

  const getRowColor = (item) => {
    if (typeof item.status === "string") {
      switch (item.status.toLowerCase()) {
        case "failed":
        case "error":
        case "ko":
          return "table-danger";
        case "warning":
        case "warn":
          return "table-warning";
        default:
          return;
      }
    }
  };
  return (
    <React.Fragment>
      {(!tableData || loading) && <Loading />}
      {tableData && (
        <div className="row mb-4">
          <div className="col-6 col-md-4">
            <div className="form-group">
              <label>Other months</label>
              <select
                className="form-control"
                defaultValue={bucket}
                onChange={(e) => setBucket(e.target.value)}
              >
                {tableData.buckets.map((bucket, index) => {
                  return (
                    <option key={index} value={bucket}>
                      {bucket}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 col-md-4">
            <div className="form-group">
              <label>From</label>
              <input
                type="date"
                value={`${year}-${month}-${zeroPad(dayStart, 2)}`}
                min={`${year}-${month}-01`}
                max={`${year}-${month}-${zeroPad(
                  getLastDayMonth(year, month),
                  2
                )}`}
                className="form-control"
                onChange={(e) => {
                  setYear(e.target.value.split("-")[0]);
                  setMonth(e.target.value.split("-")[1]);
                  setDayStart(e.target.value.split("-")[2]);
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-4">
            <div className="form-group">
              <label>To</label>
              <input
                type="date"
                value={`${year}-${month}-${zeroPad(dayEnd, 2)}`}
                min={`${year}-${month}-01`}
                max={`${year}-${month}-${zeroPad(
                  getLastDayMonth(year, month),
                  2
                )}`}
                className="form-control"
                onChange={(e) => {
                  setYear(e.target.value.split("-")[0]);
                  setMonth(e.target.value.split("-")[1]);
                  setDayEnd(e.target.value.split("-")[2]);
                }}
              />
            </div>
          </div>
          <div className="col-3">
            <div>
              <div className="form-group">
                <label>IP</label>
                <input
                  placeholder="8.8.8.8"
                  type="text"
                  name="ip"
                  value={filters.ip}
                  className="form-control"
                  onChange={(e) => {
                    const value = e.target.value;
                    setFilters((current) => {
                      return {
                        ...current,
                        ip: value,
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>User</label>
              <input
                type="text"
                className="form-control"
                id="us"
                name="us"
                value={us}
                placeholder="email@email.com/name/user id"
                onChange={handleOnChange}
              />
            </div>
          </div>
          {props.section === "rooms" && (
            <div className="col-3">
              <div className="form-group">
                <label>Room</label>
                <input
                  type="text"
                  className="form-control"
                  id="roomName"
                  name="roomName"
                  value={roomname}
                  placeholder="room name"
                  onChange={handleOnChange}
                />
              </div>
            </div>
          )}
          <div className="col-3">
            <div className="form-group">
              <label>Nick</label>
              <input
                type="text"
                className="form-control"
                id="nick"
                name="nick"
                value={nick}
                placeholder="nick"
                onChange={handleOnChange}
              />
            </div>
          </div>

          <div className="col-3">
            <div className="form-group">
              <label>Free search</label>
              <input
                type="text"
                className="form-control"
                id="free"
                name="free"
                value={free}
                placeholder="msg:Room opened v.0.1.0"
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="col-12">
            <button className="btn btn-primary mr-2" onClick={getLogs}>
              Apply filters
            </button>
            <button
              className="btn btn-primary"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
        </div>
      )}
      {tableData && (
        <div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover table-striped">
              {/* <Table striped bordered hover responsive> */}
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">User</th>
                  <th scope="col"></th>
                  <th scope="col">Event</th>
                  <th scope="col"></th>

                  <th scope="col">IP</th>
                  <th scope="col" className="text-center">
                    <b>Detail</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.data.length > 0 && (
                  <React.Fragment>
                    {tableData.data.map((item, index) => {
                      return (
                        <tr key={index} className={getRowColor(item)}>
                          <td className="small pl-2">
                            <LogDate item={item} />
                          </td>
                          <td>
                            <LogsUser
                              item={item}
                              setUs={setUs}
                              setRoomname={setRoomname}
                              setNick={setNick}
                            />
                          </td>
                          <td className="text-center">
                            <LogsSection item={item} />
                          </td>
                          <td>
                            <LogsEvents item={item} setNick={setNick} />
                          </td>

                          <td className="text-center">
                            <Logstatus status={item.status} />
                          </td>

                          <td className="text-left small">{item.ip}</td>
                          <td className="text-center">
                            <NavLink
                              className="nav-link"
                              exact
                              to={`/log/${bucket}/${item._id}`}
                            >
                              <Threepoints item={item._id} />
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            handleChangeNumberOfColum={handleChangeNumberOfColum}
            pagination={tableData.pagination}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TableLogs;
