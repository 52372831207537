import React from 'react';
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";

const UserLevel = (props) => {
    const {status} = props;
    let result;

    switch (status) {
        case 0:   // Admin
            result = <div><FontAwesomeIcon icon={faUserTie} className="mr-2" />Admin</div>;
            break;
        case 1:  // Regular User
            result = <div><FontAwesomeIcon icon={faUser} className="mr-2" />Normal</div>;
            break;
        default :   // Regular User
            result = <div><FontAwesomeIcon icon={faUser} className="mr-2" />Normal</div>;
            break;
    }
    return <>{ result }</>;
}

export default UserLevel;
