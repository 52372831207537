import request from "./api-service";

function list(type, status) {
  let req = `/admin/alerts/list?`;
  if (type !== "all") req = `${req}type=${type}`;
  return request({
    url: req,
    method: "GET",
  });
}

function dispatched(filters) {
  let req = `/dispatchedalerts/${filters.type}?`;
  if (filters.pagination) {
    if (filters.pagination.page) req = `${req}&page=${filters.pagination.page}`;
    if (filters.pagination.items)
      req = `${req}&items=${filters.pagination.items}`;
  }
  return request({
    url: req,
    method: "GET",
  });
}

function dispatchone(id) {
  return request({
    url: `/admin/alerts/dispatchone/${id}`,
    method: "GET",
  });
}

function dispatcheddetail(id) {
  return request({
    url: `/admin/alerts/dispatcheddetail/${id}`,
    method: "GET",
  });
}

function dispatch(category) {
  return request({
    url: `/admin/alerts/dispatch/${category}`,
    method: "GET",
  });
}

function set(data) {
  return request({
    url: `/admin/alerts`,
    method: "POST",
    data,
  });
}

function addUserToAlert(data) {
  return request({
    url: `/admin/alerts/user`,
    method: "POST",
    data,
  });
}
function update(data) {
  return request({
    url: `/admin/alerts`,
    method: "PUT",
    data,
  });
}
function closeAlert(id) {
  return request({
    url: `/admin/alerts/dispatched/${id}`,
    method: "PUT",
  });
}

function deleteAlert(id) {
  return request({
    url: `/admin/alerts/${id}`,
    method: "DELETE",
  });
}
function deleteUserAlert(id, email) {
  return request({
    url: `/admin/alerts/user/${id}/${email}`,
    method: "DELETE",
  });
}
function getAlert(id) {
  return request({
    url: `/admin/alerts/${id}`,
    method: "GET",
  });
}

function getActions(current) {
  let req = `/admin/alerts/actions/list?`;
  if (current) req = `${req}current=${current}`;
  return request({
    url: req,
    method: "GET",
  });
}

function getAlertsEnabled() {
  let req = `admin/alerts/enabled/status`;
  return request({
    url: req,
    method: "GET",
  });
}
function setAlertEnabled(status) {
  return request({
    url: `admin/alerts/enable/${status}`,
    method: "PUT",
  });
}

const AlertService = {
  list,
  dispatched,
  set,
  update,
  deleteAlert,
  getAlert,
  addUserToAlert,
  deleteUserAlert,
  dispatchone,
  dispatch,
  closeAlert,
  getActions,
  dispatcheddetail,
  getAlertsEnabled,
  setAlertEnabled,
};

export default AlertService;
