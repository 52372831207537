import request from "./api-service";

function list() {
  return request({
    url: `/admin/blacklistip`,
    method: "GET",
  });
}

function add(ip) {
  return request({
    url: `/admin/blacklistip`,
    method: "POST",
    data: { ip },
  });
}

function check(ip) {
  return request({
    url: `/admin/blacklistip/${ip}`,
    method: "GET",
  });
}

function deleteIP(ip) {
  return request({
    url: `/admin/blacklistip/${ip}`,
    method: "DELETE",
  });
}

const BlackListIPService = {
  add,
  list,
  check,
  deleteIP,
};

export default BlackListIPService;
