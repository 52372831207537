import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

//style
import './index.scss'

const Loading = () => {
  return (
    <div className="modal-content-loading">
        <div className="img-content-loading">
            <FontAwesomeIcon className="" spin size="9x" icon={faCircleNotch} />
        </div>
    </div>
  );
}

export default Loading;
