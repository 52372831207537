import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

// Components
import Layout from "../../components/layout";
import TableLogs from "./Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
const Logs = (props) => {
  const params = useParams();
  const [bucket, setBucket] = useState(null);
  useEffect(() => {
    getDefaultBucket();
  }, []);

  /*useEffect(() => {
    console.log("logs useEffect bucket changed", bucket);
  }, [bucket]);*/

  useEffect(() => {
    getDefaultBucket();
  }, [props.location.pathname]);

  /*componentDidUpdate() {
    this.logger.log("componentDidUpdate", this.state);
    const last = this.props.location.pathname.split("/");
    if (last[2] !== this.state.route) {
      this.getDefaultBucket();
    }
  }*/

  const getDefaultBucket = () => {
    console.log("getDefaultBucket route:", params.log);

    switch (params.log) {
      case "olecams":
        setBucket("OLECAMS_LOG");
        break;

      case "mow":
        setBucket("LOG_MOW");
        break;

      case "rooms":
        setBucket("LOG_ROOM_ERRORS");
        break;

      case "vce":
        setBucket("LOG_CHAT");
        break;

      case "voluum":
        setBucket("APIVOLUUM_LOG");
        break;

      case "images":
        setBucket("LOG_API_IMAGES");
        break;

      case "traffic":
        setBucket("TRAFFIC");
        break;

      case "oldpanel":
        setBucket("LOG_PW");
        break;

      case "videochatmb":
        setBucket("LOG_VM");
        break;

      case "visaupsell":
        setBucket("LOG_UPSELL_VISA");
        break;

      case "tpvvisa":
        setBucket("LOG_VISA");
        break;

      case "brokers":
        setBucket("LOG_BROKERS");
        break;

      case "olecamssyserror":
        setBucket("OLECAMS_SYSTEM_ERRORS");
        break;

      case "apimow":
        setBucket("API_MOW");
        break;
      case "apiolecams":
        setBucket("API_OC");
        break;
      case "oletraffic":
        setBucket("APIPW_LOG");
        break;

      case "cascadepayments":
        setBucket("CASCADE_PAYMENTS");
        break;
      default:
      // no default
    }
  };

  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <FontAwesomeIcon icon={faDatabase} className="mr-2" />
            {params.log && (
              <NavLink to={`${params.log}`} role="button">
                {params.log}
              </NavLink>
            )}
          </li>
        </ol>
      </nav>

      {bucket && <TableLogs bucket={bucket} section={params.log}></TableLogs>}
    </Layout>
  );
};

export default Logs;
