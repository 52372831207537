import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// API Service
import AlertService from "../../../services/alerts-service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Button, Modal, Form, Row, Col, Alert, Table } from "react-bootstrap";

const AlertUsers = (props) => {
  const [id] = useState(props.data.id ? props.data.id : null);
  const [email, setEmail] = useState(props.data.email ? props.data.email : "");
  const users = props.data.users ? props.data.users : [];

  const [variantAlert, setVariantAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(false);

  //  addUserToAlert,deleteUserAlert,
  const addUser = async () => {
    const [response, code] = await AlertService.addUserToAlert({
      email,
      id,
    });
    handleResponse(response, code);
  };

  //  addUserToAlert,deleteUserAlert,
  const deleteUserAlert = async (email) => {
    const [response, code] = await AlertService.deleteUserAlert(id, email);
    handleResponse(response, code);
  };

  const handleResponse = (response, code) => {
    if (code === 200) {
      props.handleClose();
    } else {
      setShowAlert(true);
      setTextAlert(response.error);
      setVariantAlert("danger");
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;

      //no default
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false}>
      {showAlert && <Alert variant={variantAlert}>{textAlert}</Alert>}
      <Modal.Header closeButton>
        <Modal.Title>Alert Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {users.length > 0 && (
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr className="text-center">
                <th>Email</th>
                <th>Delete</th>
              </tr>
            </thead>

            <tbody>
              <>
                {users.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={(index + 1) % 2 ? "impar" : "par"}
                    >
                      <td className="text-center">
                        <small>{item.email}</small>
                      </td>

                      <td className="text-center">
                        <small>
                          <Button
                            variant="danger"
                            onClick={(e) => deleteUserAlert(item.email)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </small>
                      </td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </Table>
        )}
        <Form>
          <Form.Group as={Row}>
            <Form.Label column>Email</Form.Label>
            <Col xs lg="4">
              <Form.Control
                name="email"
                type="text"
                placeholder="email"
                onChange={handleOnChange}
                value={email}
              />
            </Col>
            <Col xs lg="4">
              <Button variant="success" onClick={(e) => addUser()}>
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                <span>Add user</span>
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(AlertUsers);
