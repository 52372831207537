import React from "react";
import { withRouter } from "react-router-dom";

import { Button, Table } from "react-bootstrap";
import Failed from "../../../components/icons/failed";
import Success from "../../../components/icons/success";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faUsers } from "@fortawesome/free-solid-svg-icons";
import Launch from "../../../components/icons/launch";
import { calculateTime } from "../../../libs/methods";
//style
import "./index.scss";

const AlertTable = (props) => {
  return (
    <Table striped bordered hover responsive size="sm">
      <thead>
        <tr className="text-left">
          <th>ID</th>
          <th>Name</th>
          <th>Time</th>
          <th>Type</th>
          <th>Action</th>
          <th className="text-center">Active</th>
          <th className="text-center">Users</th>

          <th className="text-center">Dispatch</th>
          <th className="text-center">Edit</th>
          <th className="text-center">Delete</th>
        </tr>
      </thead>
      <tbody>
        {props.data.length > 0 && (
          <>
            {props.data.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={
                    (item.status === 0
                      ? "bg-warning"
                      : (index + 1) % 2
                      ? "impar"
                      : "par",
                    "tdAlerts")
                  }
                >
                  <td className="text-left">
                    <small>{item.id}</small>
                  </td>
                  <td className="text-left font-weight-bold">
                    <small>{item.name}</small>
                  </td>
                  <td className="text-left font-weight-bold">
                    <small>{calculateTime(item.dispatch)}</small>
                  </td>
                  <td className="text-left font-weight-bold">
                    <small>
                      <b>{item.type}</b>
                    </small>
                  </td>
                  <td className="text-left font-weight-bold">
                    <small>{item.action}</small>
                  </td>
                  <td className="text-center">
                    {item.status === 1 && <Success />}{" "}
                    {item.status === 0 && <Failed />}
                  </td>
                  <td className="text-center">
                    <Button
                      variant="primary"
                      onClick={(e) => props.showUsers(item.id)}
                    >
                      <FontAwesomeIcon icon={faUsers} />
                    </Button>
                  </td>
                  <td className="text-center handed">
                    <small onClick={(e) => props.dispatchAlert(item.id)}>
                      <Launch></Launch>
                    </small>
                  </td>
                  <td className="text-center">
                    <small>
                      <Button
                        variant="info"
                        onClick={(e) => props.getAlert(item.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </small>
                  </td>

                  <td className="text-center">
                    <small>
                      <Button
                        variant="danger"
                        onClick={(e) => props.deleteAlert(item.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </small>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default withRouter(AlertTable);
