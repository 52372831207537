import React from "react";
import { withRouter } from "react-router-dom";
import Success from "../../components/icons/success";
//style
import "./index.scss";

const UserForm = (props) => {
  console.log(props);
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{props.data.USERNAME}</h5>
        <p className="card-text">{props.data.EMAIL}</p>

        <p className="card-text">
          ID: <b>{props.data.ID}</b>
        </p>
        {props.data.VOLUUM_ACCESS && (
          <p className="card-text">
            Voluum Access <Success />
          </p>
        )}
      </div>
    </div>
  );
};

export default withRouter(UserForm);
