import React from "react";
import { withRouter } from "react-router-dom";
import WsHealth from "../../components/wshealth";
import VideosHealth from "../../components/videoshealth";
import OpenAlertsCounter from "../../components/openalertscounter";

//style
import "./index.scss";

const HomeContent = (props) => {
  return (
    <div className="row">
      <div className="col xl-3 md-6 mb-4">
        <WsHealth
          service="https://wstulipan.videochatprovider.com/api/health/"
          name="Websocket Production"
          type="ws"
        />
        <WsHealth
          service="https://staging-wsserver.videochatprovider.com/api/health/"
          name="Websocket Staging"
          type="ws"
        />
        <WsHealth
          service="https://preprodwstulipan.videochatprovider.com/api/health/"
          name="Websocket Preprod"
          type="ws"
        />
      </div>
      <div className="col xl-3 md-6 mb-4">
        <WsHealth
          service="production"
          name="Monitor Production"
          type="monitor"
        />
        <WsHealth service="staging" name="Monitor Staging" type="monitor" />
      </div>

      <div className="col xl-3 md-6 mb-4">
        <VideosHealth service="svcXcode" />
        <VideosHealth service="svcUpload" />
      </div>

      <div className="col-xl-3 col-md-6 mb-4">
        <OpenAlertsCounter />
      </div>
    </div>
  );
};

export default withRouter(HomeContent);
