import React, { useEffect, useState } from "react";

import LogsService from "../../services/logs-service";
import Loading from "../../components/loading";

import ReactJson from "react-json-view";

const Detail = (props) => {
  const [loading, setLoading] = useState(false);
  const [logDetail, setLogDetail] = useState(null);
  /* super(props);
    this.state = {
      bucket: props.bucket,
      id: props.id,
      errorMessage: null,
      logdetail: {},
    };*/
  // on component mount
  useEffect(() => {
    getOneLog();
  }, []);

  const getOneLog = async () => {
    if (!loading) {
      setLoading(true);
    }
    const [res, code] = await LogsService.getOneLog(props.bucket, props.id);
    if (code === 200) {
      setLoading(false);
      setLogDetail(res);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {logDetail && (
        <table className="table">
          <thead>
            <tr className="text-left">
              <th>{logDetail["fecha"]}</th>
            </tr>
          </thead>
          <tbody>
            <ReactJson displayDataTypes={false} src={logDetail} />
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

export default Detail;
