import React from "react";
import { withRouter } from "react-router-dom";

//style
import "./index.scss";

const DataTable = (props) => {
  return (
    <table className="table table-bordered table-hover table-striped table-sm">
      <thead>
        <tr className="text-left">
          <th>Filename</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {props.data.length > 0 && (
          <>
            {props.data.map((item, index) => {
              return (
                <tr key={index} className={(index + 1) % 2 ? "impar" : "par"}>
                  <td className="text-left font-weight-bold">
                    <small>{item.filename}</small>
                  </td>
                  <td className="text-left font-weight-bold">
                    <small>{item.date}</small>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};

export default withRouter(DataTable);
