import React, { useState, useEffect } from "react";
import UserService from "../../services/user-service";
import { NavLink } from "react-router-dom";

import Loading from "../../components/loading";

//style
import "./index.scss";

const Requestpwd = (props) => {
  const [user, setUser] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  // on component mount
  useEffect(() => {}, []);

  const handleChange = (e) => {
    let value = e.target.value;
    setUser(value);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (user !== "") {
      const [response, code] = await UserService.requestpass(user);
      console.log(response, code);
      if (code === 200) {
        setError(false);
        setSuccess(true);
        setErrorMsg("");
        setLoading(false);
      } else {
        setError(true);
        setErrorMsg(response.error);
        setLoading(false);
      }
    } else {
      setError(true);
      setErrorMsg("You must fill user and password");
      setLoading(false);
    }
  };

  return (
    <div className="loginLayout-container">
      {loading && <Loading />}

      <main className="form-signin">
        <div className="shadow-lg border-0 rounded-lg mt-5 card">
          <div className="card-header">
            <h4 className="text-center font-weight-light my-2">
              Request new password
            </h4>
          </div>
          <div className="card-body">
            {success && (
              <div className="alert alert-success" role="alert">
                A recovery link has been sent to your email {user}
              </div>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}
            {!success && (
              <form onSubmit={submit}>
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${error ? "is-invalid" : ""}`}
                    name="user"
                    value={user}
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 btn btn-lg"
                    // onClick={submit}
                  >
                    Request Password
                  </button>
                </div>
              </form>
            )}
            <div className="form-group">
              <div className="text-center">
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to="/"
                  role="button"
                >
                  Back
                </NavLink>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="text-center">
              <img
                className="mb-4 mr-5"
                src="/images/Marca.png"
                alt=""
                width="300"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Requestpwd;
