import React from "react";

const Law = (props) => {
  const currentColor = props.color ? props.color : "#000";
  const width = props.width ? props.width : 24;
  const height = props.height ? props.height : 24;
  return (
    <svg
      enable-background="new 0 0 48 48"
      id="Layer_3"
      version="1.1"
      viewBox="0 0 64 64"
      width={width}
      height={height}
      fill={currentColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.766,26.698l-0.027-0.062l0.015-0.012l-0.198-0.378L52.936,6.025c-0.29-0.528-1.299-2.078-3.24-2.078  H14.119c-2.166,0-3.25,2.134-3.274,2.184L0.415,25.99l-0.004,0.009l-0.074,0.14c-0.175,0.346-0.563,1.286-0.178,2.214  c2.65,4.302,7.374,7.188,12.797,7.188c5.302,0,9.927-2.765,12.607-6.908c0.41-0.941-0.161-2.12-0.161-2.12L15.534,7.721  l-0.096-0.182c-0.133-0.306-0.251-0.889,0.807-0.889h12.938c0.367,0.012,1.39,0.194,1.39,1.907v44.518  c-0.024,0.441-0.191,1.334-1.108,1.9l-6.477,4.006c0,0-1.699,1.072,0,1.072h18.379c1.253,0,0-0.869,0-0.869L34.695,55.1  c-0.003-0.002-0.003-0.004-0.006-0.006c-1.097-0.672-1.302-1.518-1.332-1.945V8.367c0-1.548,1.11-1.708,1.5-1.717h12.651  c0.331,0.006,1.34,0.116,0.705,1.331l-9.907,18.858c0,0-0.31,0.742,0.039,1.556c2.653,4.278,7.359,7.146,12.765,7.146  c5.415,0,10.138-2.874,12.788-7.166C64.163,27.683,63.865,26.915,63.766,26.698z M22.198,26.104l0.001,0.001l0.136,0.261  c0.21,0.434,0.527,1.26-0.136,1.26L3.391,27.624c-0.624,0-0.5-0.571-0.301-1.032l0.298-0.566l0.003-0.005l8.966-17.072  c0,0,0.458-0.708,0.83,0h0.001L22.198,26.104z M41.578,26.311l0.133-0.253c0-0.001,0.005-0.01,0.005-0.01l8.766-16.691  c0.012-0.018,0.607-1.15,1.213,0.003l8.74,16.639c0.181,0.358,0.734,1.629-0.563,1.629l-17.455-0.003c-0.003,0-0.003,0-0.003,0  C41.142,27.624,41.372,26.758,41.578,26.311z"
        fill="#241F20"
      />
    </svg>
  );
};

export default Law;
