import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
//style
import "./index.scss";

const Pagination = (props) => {
  const items = [10, 50, 100, 150];
  let pagesNumber = [];
  if (props.pagination)
    for (let i = 1; i <= props.pagination.pages; i++) {
      pagesNumber.push(i);
    }
  const handleOnChange = (e) => {
    const { value } = e.target;
    props.handleChangeNumberOfColum(value);
  };
  //handleChangeNumberOfColum;
  return (
    <div className="paginate-content  p-3">
      {props.pagination.total && (
        <div className="number-page">Total Items {props.pagination.total}</div>
      )}

      <select
        className="select-items"
        onChange={handleOnChange}
        defaultValue={parseInt(props.pagination.limit)}
      >
        {items.length > 0 &&
          items.map((number) => {
            if (parseInt(props.pagination.limit) === number) {
              return (
                <option key={number} value={number}>
                  {number}
                </option>
              );
            } else {
              return (
                <option key={number} value={number}>
                  {number}
                </option>
              );
            }
          })}
      </select>

      {props.pagination && (
        <div className="number-page">
          {props.pagination.page > 1 && (
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="mr-2"
              onClick={(e) => props.prevPage(props.pagination.page - 1)}
            />
          )}
          {pagesNumber.length > 1 && pagesNumber < 50 && (
            <select
              className="select-pages"
              onChange={props.paginate}
              defaultValue={parseInt(props.pagination.page)}
            >
              {pagesNumber.length > 0 &&
                pagesNumber.map((number) => {
                  if (parseInt(props.pagination.page) === number) {
                    return (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    );
                  } else {
                    return (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    );
                  }
                })}
            </select>
          )}
          {props.pagination.pages > 50 && (
            <span className="mr-2 ml-2">
              {props.pagination.page} of {props.pagination.pages}
            </span>
          )}
          {props.pagination.pages > 1 && (
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="mr-2"
              onClick={(e) => props.nextPage(props.pagination.page + 1)}
            />
          )}
        </div>
      )}
    </div>
  );
};
Pagination.defaultProps = {
  item: false,
};

export default Pagination;
