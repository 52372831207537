import React from "react";

const Affiliates = (props) => {
  const currentColor = props.color ? props.color : "#000";
  const width = props.width ? props.width : 24;
  const height = props.height ? props.height : 24;
  return (
    <svg
      enable-background="new 0 0 48 48"
      id="Layer_3"
      version="1.1"
      viewBox="0 0 48 48"
      width={width}
      height={height}
      fill={currentColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="23.968" cy="25.318" fill={currentColor} r="7.317" />
        <path
          d="M30.353,32.533c-1.701,1.507-3.933,2.431-6.385,2.431c-2.44,0-4.662-0.915-6.361-2.409   c-4.432,2.419-7.46,7.271-7.46,12.867c0,0.88,0.08,1.74,0.223,2.578h27.262c0.144-0.838,0.223-1.698,0.223-2.578   C37.854,39.81,34.807,34.943,30.353,32.533z"
          fill={currentColor}
        />
        <path
          d="M43.89,28.38v-9.861c1.208-0.913,2-2.347,2-3.978c0-2.761-2.238-5-5-5c-0.942,0-1.813,0.276-2.565,0.729   l-9.369-5.41C28.879,2.166,26.683,0,23.968,0c-2.727,0-4.935,2.186-4.99,4.899L9.607,10.31C8.84,9.831,7.94,9.542,6.969,9.542   c-2.762,0-5,2.239-5,5c0,1.697,0.85,3.19,2.142,4.094v9.744c-1.25,0.909-2.071,2.372-2.071,4.036c0,2.762,2.238,5,5,5s5-2.238,5-5   c0-2.539-1.899-4.613-4.35-4.935v-8.013c2.417-0.352,4.279-2.413,4.279-4.927c0-0.457-0.081-0.892-0.196-1.312l8.489-4.901   C21.177,9.347,22.491,10,23.968,10c1.49,0,2.812-0.664,3.728-1.697l8.411,4.856c-0.128,0.442-0.218,0.899-0.218,1.382   c0,2.563,1.937,4.652,4.422,4.942v7.998c-2.451,0.321-4.351,2.396-4.351,4.935c0,2.762,2.238,5,5,5s5-2.238,5-5   C45.961,30.752,45.14,29.288,43.89,28.38z"
          fill={currentColor}
        />
      </g>
    </svg>
  );
};

export default Affiliates;
