import React from "react";

const Mode = (props) => {
  const width = props.width ? props.width : 24;
  const height = props.height ? props.height : 24;
  return (
    <svg
      enableBackground="new 0 0 32 32"
      height={width}
      id="Слой_1"
      version="1.1"
      viewBox="0 0 32 32"
      width={height}
    >
      <path
        clipRule="evenodd"
        d="M30,1.982H2c-1.105,0-2,0.896-2,2v18  c0,1.105,0.895,2,2,2h5c0.641,0,1-0.453,1-1s-0.34-1.013-1-1.013H3.22c-0.668,0-1.21-0.542-1.21-1.21V5.237  c0-0.668,0.542-1.21,1.21-1.21l25.571-0.031c0.668,0,1.21,0.542,1.21,1.21v15.553c0,0.668-0.542,1.21-1.21,1.21H17.419l5.188-5.262  c0.39-0.395,0.39-1.034,0-1.429c-0.391-0.394-1.024-0.394-1.414,0l-6.9,6.999c-0.39,0.394-0.39,1.034,0,1.429c0,0,0,0,0.001,0  l6.899,6.999c0.39,0.394,1.024,0.394,1.414,0c0.39-0.395,0.39-1.034,0-1.428l-5.218-5.294H30c1.105,0,2-0.895,2-2v-18  C32,2.878,31.105,1.982,30,1.982z"
        fill="#121313"
        fillRule="evenodd"
        id="Loop"
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default Mode;
