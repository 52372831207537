import React from "react";
import classNames from "classnames";
import { Container } from "react-bootstrap";
import { isEmpty } from 'lodash'
import NavBar from "./Navbar";
import Footer from "../footer";
import { withRouter } from 'react-router-dom'

// Components
import isAuth from '../../libs/isAuth'

import "./index.scss";

class Content extends React.Component {
  render() {
    return (
      <Container
        fluid
        className={classNames("content d-flex flex-column min-vh-100", { "is-open": this.props.isOpen })}
      >
        <NavBar
            toggle={this.props.toggle}
            username={!isEmpty(this.props.auth) && this.props.auth.username}
            logoutUser={this.props.logoutUser}
        />
        <main>{this.props.content}</main>
        <Footer />
      </Container>

    );
  }
}

export default withRouter(((isAuth()(Content))))
