import React, { useEffect, useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../../libs/isAuth";

//Services
import BlackListEmailService from "../../../services/blacklistemail-service";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faPlus,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from "../../../components/layout";
import Loading from "../../../components/loading";
import Table from "./table.js";
//style
import "./index.scss";
const BlackListEmail = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // on component mount
  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    setLoading(true);
    BlackListEmailService.list()
      .then(([response, code]) => {
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const add = () => {
    if (email === "") return;
    setLoading(true);
    BlackListEmailService.add(email)
      .then(([response, code]) => {
        if (code === 200) {
          toast.success("Email domain added to blacklist");
        } else {
          toast.success("Error adding Email domain to blacklist");
        }
        getList();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const check = () => {
    if (email === "") return;
    setLoading(false);
    BlackListEmailService.check(email)
      .then(([response, code]) => {
        console.log(response, code);
        if (response) {
          toast.success(`${email} is banned`);
        } else {
          toast.error(`${email} is not banned`);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteData = () => {
    if (window.confirm("Are you sure you want to delete this email domain?")) {
      setLoading(false);
      BlackListEmailService.deleteDomain(email)
        .then(([response, code]) => {
          if (code === 200) {
            toast.success(`${email} deleted`);
          } else {
            toast.error(`Error deleting ${email}`);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <NavLink exact={true} activeClassName="active" to="/" role="button">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <FontAwesomeIcon icon={faList} className="mr-2" />
              Email domain Blacklist
            </li>
          </ol>
        </NavLink>
      </nav>
      {loading && <Loading />}

      <div className="row mb-4">
        <div className="col-2 col-md-2">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={email}
              placeholder="domain.com"
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div>
          <button className="btn btn-primary mr-2" onClick={add}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Email domain
          </button>
        </div>
        <div>
          <button className="btn btn-info mr-2" onClick={check}>
            <FontAwesomeIcon icon={faCheck} className="mr-2" /> Check domain
          </button>
        </div>
        <div>
          <button className="btn btn-danger mr-2" onClick={deleteData}>
            <FontAwesomeIcon icon={faTrash} className="mr-2" /> Delete Email
          </button>
        </div>
      </div>

      {
        <div className="row justify-content-md-center">
          <div className="col">
            <Table data={data} />
          </div>
        </div>
      }
    </Layout>
  );
};

export default withRouter(isAuth()(BlackListEmail));
