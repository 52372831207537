import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faPowerOff, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { getAuth } from '../../libs/cookies';

class NavBar extends React.Component {
  render() {
    return (
      <Navbar
        bg="light"
        className="navbar shadow-sm p-3 bg-white rounded"
        expand
      >
        <Button variant="outline-dark" onClick={this.props.toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto" navbar>
                <NavLink exact={true} activeClassName='active' to="/user" role="button" className="nav-link">
                <FontAwesomeIcon size="2x" fixedWidth icon={faUserCircle} className="userIcon" />
                { getAuth().username } { parseInt(getAuth().level) === 0 ? ' (Admin) ' : '' }
                </NavLink>
                <div className="bottons-content logout-button">
                    <Button variant="outline-danger" onClick={ e => this.props.logoutUser() }>
                        <FontAwesomeIcon icon={faPowerOff} />
                    </Button>
                </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
