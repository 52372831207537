import React, { useEffect, useState } from "react";
import HealthService from "../../services/health-service";
import classNames from "classnames";
import Success from "../icons/success";
import Failed from "../icons/failed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
const VideosHealth = (props) => {
  const [allOk, setAllOk] = useState(null);

  useEffect(() => {
    HealthService.checkVideosHealth(props.service).then(([res, code]) => {
      if (code === 200) {
        setAllOk(true);
      } else {
        setAllOk(false);
      }
    });
  }, []);

  return (
    <div className="card shadow">
      <div
        className={classNames(
          "card-body",
          { "border-left-warning": allOk === null },
          { "border-left-danger": !allOk },
          { "border-left-success": allOk }
        )}
      >
        <div className="no-gutters align-items-center">
          <h6 className="card-title small">Service {props.service}</h6>

          <p className="card-text">
            {allOk === null && (
              <FontAwesomeIcon
                className=""
                spin
                size="2x"
                icon={faCircleNotch}
              />
            )}
            {allOk === false && <Failed />}
            {allOk === true && <Success />}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideosHealth;
