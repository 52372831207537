import React, { useEffect, useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../../libs/isAuth";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import HealthService from "../../../services/health-service";
import { toast } from "react-toastify";

import Layout from "../../../components/layout";
const Websockets = (props) => {
  const [prod, setProd] = useState(null);
  const [prodStatus, setProdStatus] = useState(null);
  const [staging, setStaging] = useState(null);
  const [stagingStatus, setStagingStatus] = useState(null);
  const [preprod, setPreProd] = useState(null);
  const [preprodStatus, setPreProdStatus] = useState(null);
  useEffect(() => {
    getWsInstance("production");
    getWsInstance("staging");
    getWsInstance("preprod");
  }, []);
  const reboot = (env) => {
    let rebootCode = prompt("Reboot code", "");
    if (env === "production") {
      setProdStatus(null);
    } else if (env === "preprod") {
      setPreProdStatus(null);
    } else {
      setStagingStatus(true);
    }
    HealthService.rebootWs(env, rebootCode).then(([res, code]) => {
      if (code === 200) {
        toast.error("Rebooting ws");
        getWsInstance(env);
      } else {
        toast.error(res.error);
        setProdStatus(true);
      }
    });
  };
  const getWsInstance = (env) => {
    HealthService.getWsInstance(env).then(([res, code]) => {
      if (code === 200) {
        if (env === "production") {
          setProd(res);
          setProdStatus(true);
        } else if (env === "preprod") {
          setPreProd(res);
          setPreProdStatus(true);
        } else {
          setStaging(res);
          setStagingStatus(true);
        }
      } else {
        if (env === "production") {
          setProdStatus(false);
        } else if (env === "preprod") {
          setPreProdStatus(false);
        } else {
          setStagingStatus(false);
        }
      }
    });
  };
  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <NavLink exact={true} activeClassName="active" to="/" role="button">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <FontAwesomeIcon icon={faNetworkWired} className="mr-2" />
              Websockets
            </li>
          </ol>
        </NavLink>
      </nav>
      <div className="row">
        {prod && (
          <div className="col xl-3 md-6 mb-4">
            <div className="card shadow">
              <div
                className={classNames(
                  "card-body",
                  { "border-left-warning": prodStatus === null },
                  { "border-left-danger": !prodStatus },
                  { "border-left-success": prodStatus }
                )}
              >
                <div className="no-gutters align-items-center">
                  <h6 className="card-title">Websocket Production</h6>
                  <p className="card-text small">{prod.name}</p>
                  <p className="card-text small">Server: {prod.server}</p>
                  <p className="card-text small">Version {prod.version}</p>

                  <p className="card-text">
                    <b>Rooms</b> {prod.rooms.length}
                  </p>
                  <p className="card-text">
                    <b>Users</b> {prod.users.length}
                  </p>

                  <p className="card-text">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        reboot("production");
                      }}
                    >
                      Reboot
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {preprod && (
          <div className="col xl-3 md-6 mb-4">
            <div className="card shadow">
              <div
                className={classNames(
                  "card-body",
                  { "border-left-warning": preprodStatus === null },
                  { "border-left-danger": !preprodStatus },
                  { "border-left-success": preprodStatus }
                )}
              >
                <div className="no-gutters align-items-center">
                  <h6 className="card-title">Websocket Preprod</h6>
                  <p className="card-text small">{preprod.name}</p>
                  <p className="card-text small">Server: {preprod.server}</p>
                  <p className="card-text small">Version {preprod.version}</p>

                  <p className="card-text">
                    <b>Rooms</b> {preprod.rooms.length}
                  </p>
                  <p className="card-text">
                    <b>Users</b> {preprod.users.length}
                  </p>

                  <p className="card-text">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        reboot("preprod");
                      }}
                    >
                      Reboot
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {staging && (
          <div className="col xl-3 md-6 mb-4">
            <div className="card shadow">
              <div
                className={classNames(
                  "card-body",
                  { "border-left-warning": stagingStatus === null },
                  { "border-left-danger": !stagingStatus },
                  { "border-left-success": stagingStatus }
                )}
              >
                <div className="no-gutters align-items-center">
                  <h6 className="card-title">Websocket Staging</h6>
                  <p className="card-text small">{staging.name}</p>
                  <p className="card-text small">Server: {staging.server}</p>
                  <p className="card-text small">Version {staging.version}</p>

                  <p className="card-text">
                    <b>Rooms</b> {staging.rooms.length}
                  </p>
                  <p className="card-text">
                    <b>Users</b> {staging.users.length}
                  </p>

                  <p className="card-text">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        reboot("staging");
                      }}
                    >
                      Reboot
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withRouter(isAuth()(Websockets));
