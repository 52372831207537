import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
//libs
import { setDevBugCookie } from "../../libs/cookies";
import { devbugData } from '../../libs/methods'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
//Bootstrap
import { Button, Modal, Form } from 'react-bootstrap';

class DevbugModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            apiUrl: '',
        }
    }

    componentDidMount() {
    }

    handleChangeSelected = (e) => this.setState({ apiUrl: e.target.value })

    saveChange = () => {
        if(this.state.apiUrl === '' ) {
            setDevBugCookie(process.env.REACT_APP_API_URL)
        } else {
            setDevBugCookie(this.state.apiUrl)
        }
        this.props.closeModal()
    }

    render() {

        return (
                <Modal show={this.props.show} onHide={this.props.closeModal} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Devbug Selector</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert alert-success" role="alert">
                            API actual: {this.state.apiUrl === '' ? devbugData() : this.state.apiUrl }
                        </div>
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    custom
                                    name="api_env"
                                    placeholder="API ENV"
                                    onChange={this.handleChangeSelected}
                                >
                                <option value={process.env.REACT_APP_API_URL}>Según variable de entorno</option>
                                <option value="https://apibigbrother.invertred.com">Forzar API</option>
                                <option value="https://devapibigbrother.invertred.com">Forzar DEVAPI</option>
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.closeModal}><FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Cerrar</Button>
                        <Button variant="success" onClick={ e => this.saveChange() }><FontAwesomeIcon icon={faCheck} className="mr-2" /> Guardar</Button>
                    </Modal.Footer>
                </Modal>
        )
    }
}

export default withRouter((DevbugModal))
