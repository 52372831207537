import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faInfo,
  faStar,
  faUser as fasUser,
  faTimes,
  faCreditCard,
  faHeart,
  faDollarSign,
  faComments,
  faVideo,
  faWifi,
  faMicrophone,
  faPause,
  faUsers,
  faUserPlus,
  faUserMinus,
  faPhone,
  faPhoneSlash,
  faUserSlash,
  faUserEdit,
  faUsersSlash,
  faCommentSlash,
  faPercent,
  faTable,
  faBell,
  faPhotoVideo,
  faDoorClosed,
  faDoorOpen,
  faUpload,
  faPersonDress,
  faKey,
  faEarthEurope,
  faDatabase,
  faClipboard,
  faTag,
  faTags,
  faCircleExclamation,
  faWarning,
  faCalendar,
  faIdCard,
  faChartLine,
  faHashtag,
  faEnvelope,
  faBug,
  faScrewdriverWrench,
  faCrown,
  faFileArrowUp,
  faNewspaper,
  faShield,
  faList,
  faLink,
  faTowerBroadcast,
  faSkull,
  faVial,
  faMobileRetro,
  faHome,
  faBuilding,
  faGift,
  faCoins,
  faMask,
} from "@fortawesome/free-solid-svg-icons";
import { faUser, faHandshake } from "@fortawesome/free-regular-svg-icons";
import Vip from "../../icons/vip";
import Toy from "../../icons/toy";
import Mode from "../../icons/mode";
import Devices from "../../icons/devices";
import Success from "../../icons/success";
import Failed from "../../icons/failed";
import Priority from "../../icons/priority";
import Affiliates from "../../icons/affiliates";
import Banners from "../../icons/banners";
import Brokers from "../../icons/brokers";
import Law from "../../icons/law";
import Products from "../../icons/products";

import {
  faAppStore,
  faGoogle,
  faUikit,
} from "@fortawesome/free-brands-svg-icons";

const LogsSection = (props) => {
  //  console.log("LogsSection", props.item.evt);
  if (!props.item.evt) return <FontAwesomeIcon icon={faInfo} />;
  if (!props.item.msg) return <FontAwesomeIcon icon={faInfo} />;

  if (typeof props.item.evt !== "string")
    return <FontAwesomeIcon icon={faInfo} />;
  if (typeof props.item.msg !== "string")
    return <FontAwesomeIcon icon={faInfo} />;
  switch (props.item.evt.toLowerCase()) {
    case "user":
    case "users":
    case "login api mow":
    case "mow login":
    case "login":
    case "affiliateprofile":
    case "userprofile":
      if (props.item.msg === "User trying to enter room") {
        return <FontAwesomeIcon icon={faUser} />;
      } else if (props.item.msg === "User enters room") {
        return <FontAwesomeIcon icon={faUserPlus} style={{ color: "green" }} />;
      } else if (props.item.msg.toLowerCase() === "start payment cg") {
        return (
          <FontAwesomeIcon icon={faCreditCard} style={{ color: "orange" }} />
        );
      } else if (props.item.msg === "logout OK") {
        return (
          <FontAwesomeIcon icon={faUserSlash} style={{ color: "green" }} />
        );
      } else if (props.item.msg === "Register OK") {
        return <FontAwesomeIcon icon={faUserEdit} style={{ color: "green" }} />;
      } else if (props.item.msg === "User leaves room") {
        return <FontAwesomeIcon icon={faUserMinus} style={{ color: "red" }} />;
      } else if (
        props.item.msg.toLowerCase() === "login ok" ||
        props.item.msg.toLowerCase() === "login ope ok" ||
        props.item.msg.toLowerCase() === "login admin ok" ||
        props.item.msg.toLowerCase() === "user field updated" ||
        props.item.msg.toLowerCase() === "login mow ok" ||
        props.item.msg.toLowerCase() === "user register" ||
        props.item.msg.toLowerCase() === "user register (google)" ||
        props.item.msg.toLowerCase() === "user login (google)" ||
        props.item.msg.toLowerCase() === "user register (twitter)" ||
        props.item.msg.toLowerCase().includes("login ok") ||
        props.item.msg.toLowerCase() === "login to api mow ok"
      ) {
        return <FontAwesomeIcon icon={fasUser} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase() === "ip overquota" ||
        props.item.msg.toLowerCase() === "register ko - nick exists" ||
        props.item.msg.toLowerCase() === "register ko - user exists" ||
        props.item.msg.toLowerCase() === "login ko - user doesn't exists" ||
        props.item.msg.toLowerCase() === "login ko - user inactive" ||
        props.item.msg.toLowerCase() === "temporal mail" ||
        props.item.msg.toLowerCase() === "invalid recaptcha" ||
        props.item.msg.toLowerCase() === "logout ko" ||
        props.item.msg.toLowerCase().includes("login ko") ||
        props.item.msg.toLowerCase().includes("nick change not allowed") ||
        props.item.msg.toLowerCase() === "blocked account" ||
        props.item.msg.toLowerCase() === "cuenta bloqueada" ||
        props.item.msg.toLowerCase() === "incorrect data" ||
        props.item.msg.toLowerCase() === "invalid email" ||
        props.item.msg.toLowerCase() ===
          "user not exists or non active account" ||
        props.item.msg.toLowerCase() === "validation error" ||
        props.item.msg.toLowerCase() ===
          "no pwdcr - password normal erróneo o usuario inexistente" ||
        props.item.msg.toLowerCase().includes("pwdcr erróneo") ||
        props.item.msg.toLowerCase() === "login ko user blocked" ||
        props.item.msg.toLowerCase() === "invalid nick"
      ) {
        return <FontAwesomeIcon icon={fasUser} style={{ color: "red" }} />;
      } else if (
        props.item.msg.toLowerCase() === "field active updated" ||
        props.item.msg.toLowerCase() === "confirm email ok"
      ) {
        return <FontAwesomeIcon icon={fasUser} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase() === "api mow: password expired."
      ) {
        return <FontAwesomeIcon icon={faKey} style={{ color: "red" }} />;
      } else if (
        props.item.msg.toLowerCase() === "recover password ok" ||
        props.item.msg.toLowerCase() === "update password ok"
      ) {
        return <FontAwesomeIcon icon={faKey} style={{ color: "green" }} />;
      } else if (props.item.msg.toLowerCase() === "disconnect beacon") {
        return <FontAwesomeIcon icon={faUserMinus} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={fasUser} />;
      }
    case "callbacks":
      if (props.item.msg.toLowerCase() === "user register (callbacks)") {
        return <FontAwesomeIcon icon={faUserEdit} style={{ color: "green" }} />;
      } else if (props.item.msg.toLowerCase() === "login ok") {
        return <FontAwesomeIcon icon={fasUser} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faBell} />;
      }
    case "tests":
      return <FontAwesomeIcon icon={faVial} />;
    case "login_ok":
      return <FontAwesomeIcon icon={fasUser} style={{ color: "green" }} />;
    case "free token":
      return <FontAwesomeIcon icon={faHashtag} />;
    case "destroy result":
      return <FontAwesomeIcon icon={faUsersSlash} style={{ color: "red" }} />;
    case "free user":
      if (props.item.msg === "Free user leaves room") {
        return <FontAwesomeIcon icon={faUsersSlash} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faUsers} style={{ color: "green" }} />;
      }
    case "rooms":
      if (props.item.status === "Accepted") {
        return <FontAwesomeIcon icon={faList} style={{ color: "green" }} />;
      } else if (props.item.status === "Failed") {
        return <FontAwesomeIcon icon={faList} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faList} />;
      }
    case "check_user_exists":
      return <FontAwesomeIcon icon={faUsers} />;
    case "update pwd":
    case "otl password":
    case "init reset password":
    case "otl link":
      return <FontAwesomeIcon icon={faKey} />;
    case "uploader":
      if (
        props.item.msg.toLowerCase() ===
        "el fichero es válido y se subió con éxito."
      ) {
        return <FontAwesomeIcon icon={faUpload} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faUpload} />;
      }
    case "register":
      if (
        props.item.msg.toLowerCase() === "validation error" ||
        props.item.msg.toLowerCase() === "invalid email" ||
        props.item.msg.toLowerCase() === "temporal email" ||
        props.item.msg.toLowerCase() === "invalid nick"
      ) {
        return <FontAwesomeIcon icon={faUserEdit} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faUserEdit} />;
      }
    case "messages":
      return <FontAwesomeIcon icon={faEnvelope} />;
    case "room_open":
      return <FontAwesomeIcon icon={faDoorOpen} style={{ color: "green" }} />;
    case "menu":
      return <FontAwesomeIcon icon={faDoorOpen} style={{ color: "orange" }} />;
    case "broadcast":
      if (props.item.msg.toLowerCase().includes("room mode changed")) {
        return <Mode />;
      } else if (props.item.msg.toLowerCase() === "start broadcast") {
        return (
          <FontAwesomeIcon icon={faTowerBroadcast} style={{ color: "green" }} />
        );
      } else if (props.item.msg.toLowerCase() === "broadcast paused") {
        return <FontAwesomeIcon icon={faPause} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faInfo} />;
      }
    case "room closed":
    case "room_disconnect":
    case "room":
      if (
        props.item.msg.toLowerCase() === "forceclose" ||
        props.item.msg.toLowerCase() === "button close room clicked" ||
        props.item.msg.toLowerCase() === "room closed"
      ) {
        return <FontAwesomeIcon icon={faDoorClosed} style={{ color: "red" }} />;
      } else if (
        props.item.msg.toLowerCase() === "button resume broadcast clicked" ||
        props.item.msg.toLowerCase() === "button pause broadcast clicked"
      ) {
        return <FontAwesomeIcon icon={faPause} />;
      } else if (props.item.msg.toLowerCase() === "lively-pvtkey-error") {
        return <FontAwesomeIcon icon={faWarning} style={{ color: "orange" }} />;
      } else if (
        props.item.msg.toLowerCase() === "login ok" ||
        props.item.msg.toLowerCase() === "login ok"
      ) {
        return <FontAwesomeIcon icon={fasUser} style={{ color: "green" }} />;
      } else if (props.item.msg.toLowerCase() === "no audio/video devices") {
        return <Devices color="red" />;
      } else if (props.item.msg.toLowerCase() === "devices") {
        return <Devices />;
      } else if (props.item.msg.toLowerCase() === "room kicked") {
        return <FontAwesomeIcon icon={faSkull} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faDoorOpen} />;
      }
    case "add_room":
      return <FontAwesomeIcon icon={faHome} />;
    case "partners":
      return <FontAwesomeIcon icon={faHandshake} />;
    case "login_ko":
    case "logout":
      return <FontAwesomeIcon icon={faUserSlash} style={{ color: "red" }} />;

    case "room_status":
      if (props.item.msg.toLowerCase().includes("online")) {
        return (
          <FontAwesomeIcon icon={faTowerBroadcast} style={{ color: "green" }} />
        );
      } else {
        return (
          <FontAwesomeIcon icon={faTowerBroadcast} style={{ color: "red" }} />
        );
      }
    case "payments":
    case "entrar_visa_compra_caducada":
    case "entrar_visa":
    case "insert_usuarios_activos_visa":
    case "gateway":
      if (props.item.msg.toLowerCase() === "onetap not available") {
        return <FontAwesomeIcon icon={faCreditCard} style={{ color: "red" }} />;
      } else if (
        props.item.msg.toLowerCase() === "ga hit sent (cg)" ||
        props.item.msg.toLowerCase() === "ga hit sent (segpay)" ||
        props.item.msg.toLowerCase() === "ga hit sent (inespay)" ||
        props.item.msg.toLowerCase() === "ga hit sent (d24)" ||
        props.item.msg.toLowerCase() === "ga hit sent (D24)"
      ) {
        return <FontAwesomeIcon icon={faGoogle} style={{ color: "green" }} />;
      } else if (props.item.msg.toLowerCase() === "update user_profile") {
        return <FontAwesomeIcon icon={faUser} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase().includes("attem") ||
        props.item.msg.toLowerCase().includes("performing") ||
        props.item.msg.toLowerCase().includes("callback has been hit")
      ) {
        return (
          <FontAwesomeIcon icon={faCreditCard} style={{ color: "orange" }} />
        );
      } else if (
        props.item.msg.toLowerCase() === "instant transfer success" ||
        props.item.msg.toLowerCase() === "updating user credits"
      ) {
        return (
          <FontAwesomeIcon icon={faCreditCard} style={{ color: "green" }} />
        );
      } else {
        return <FontAwesomeIcon icon={faCreditCard} />;
      }
    case "entrar_visa_no_compra":
      return <FontAwesomeIcon icon={faCreditCard} style={{ color: "red" }} />;
    case "update_usuarios_activos_visa":
    case "insert_stats_visa":
      return <FontAwesomeIcon icon={faCreditCard} style={{ color: "green" }} />;
    case "purchase_check":
      return (
        <FontAwesomeIcon icon={faCreditCard} style={{ color: "orange" }} />
      );
    case "webcammer cache":
    case "reload cache":
      return <FontAwesomeIcon icon={faDatabase} style={{ color: "orange" }} />;

    case "generate manifest":
      return <FontAwesomeIcon icon={faClipboard} style={{ color: "green" }} />;
    case "reload es":
      return <img src="/images/es.png" width="64" alt="elastic search" />;
    case "envio_emails_bienvenida":
      return <FontAwesomeIcon icon={faEnvelope} style={{ color: "green" }} />;
    case "devbug":
      return <FontAwesomeIcon icon={faBug} style={{ color: "orange" }} />;
    case "js_loaded":
      return <img src="/images/js.png" width="32" />;
    case "valoration":
      if (
        props.item.msg.toLowerCase() === "addvaloration" ||
        props.item.msg.toLowerCase().includes("performer valoration ok")
      ) {
        return <FontAwesomeIcon icon={faStar} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase() === "addvaloration" ||
        props.item.msg.toLowerCase().includes("performer valoration failed")
      ) {
        return <FontAwesomeIcon icon={faStar} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faStar} />;
      }

    case "app":
      return <FontAwesomeIcon icon={faAppStore} />;
    case "notifications":
    case "edit notificacion":
      if (
        props.item.msg.toLowerCase() === "validation error" ||
        props.item.msg.toLowerCase() === "subscription not found" ||
        props.item.msg.toLowerCase().includes("ko")
      ) {
        return <FontAwesomeIcon icon={faBell} style={{ color: "red" }} />;
      } else if (props.item.msg.toLowerCase().includes("ok")) {
        return <FontAwesomeIcon icon={faBell} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faBell} />;
      }
    case "drop":
      return <FontAwesomeIcon icon={faBell} style={{ color: "red" }} />;
    case "lively":
    case "changequality":
    case "webrtc streamer":
    case "createlivelyuser":
      if (
        props.item.msg.toLowerCase() === "chat room created" ||
        props.item.msg.toLowerCase() === "chat room mode updated" ||
        props.item.msg.toLowerCase() === "chat user created" ||
        props.item.msg.toLowerCase() === "create chat room"
      ) {
        return <FontAwesomeIcon icon={faComments} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase() === "chat room destroyed" ||
        props.item.msg.toLowerCase() === "destroy chat room" ||
        props.item.msg.toLowerCase() === "delete chat user" ||
        props.item.msg.toLowerCase() === "chat - free chat destroyed"
      ) {
        return (
          <FontAwesomeIcon icon={faCommentSlash} style={{ color: "red" }} />
        );
      } else {
        return <img src="/images/lively.png" witdh="32" />;
      }
    case "ui error":
      return <FontAwesomeIcon icon={faUikit} />;
    case "user cam":
      if (
        props.item.msg.toLowerCase() === "js room - trying to play user cam" ||
        props.item.msg.toLowerCase() === "trying to play user cam"
      ) {
        return <FontAwesomeIcon icon={faVideo} style={{ color: "orange" }} />;
      } else if (
        props.item.msg.toLowerCase() ===
          "js usercam - webrtc stream received" ||
        props.item.msg.toLowerCase() ===
          "js usercam - webrtc call established" ||
        props.item.msg.toLowerCase() === "js room - playing user cam"
      ) {
        return <FontAwesomeIcon icon={faVideo} style={{ color: "green" }} />;
      } else if (props.item.msg.toLowerCase() === "stop user cam") {
        return <FontAwesomeIcon icon={faVideo} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faVideo} />;
      }

    case "ws_status":
      if (props.item.msg.toLowerCase() === "ws - connection_accepted") {
        return <FontAwesomeIcon icon={faWifi} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase() === "ws - trying to connect" ||
        props.item.msg.toLowerCase() === "ws - reconnect"
      ) {
        return <FontAwesomeIcon icon={faWifi} style={{ color: "orange" }} />;
      } else if (
        props.item.msg.toLowerCase() === "ws - connection_failed" ||
        props.item.msg.toLowerCase() === "ws - disconnect"
      ) {
        return <FontAwesomeIcon icon={faWifi} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faWifi} />;
      }
    case "setRoomOnline":
    case "setroomonline":
      if (props.item.msg.toLowerCase() === "set room online") {
        return <FontAwesomeIcon icon={faWifi} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faWifi} style={{ color: "red" }} />;
      }
    case "rates":
      return <FontAwesomeIcon icon={faPercent} />;
    case "index":
      return <FontAwesomeIcon icon={faTable} />;
    case "add rate":
    case "update rate":
      return <FontAwesomeIcon icon={faPercent} style={{ color: "green" }} />;
    case "webcammer":
    case "data":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return <FontAwesomeIcon icon={faTable} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faTable} />;
      }

    case "devices":
    case "cam":
      if (
        props.item.msg.toLowerCase() ===
        "js room - camera unauthorized or no media devices"
      ) {
        return <FontAwesomeIcon icon={faMicrophone} style={{ color: "red" }} />;
      } else if (props.item.msg.toLowerCase() === "camera or mic allowed") {
        return (
          <FontAwesomeIcon icon={faMicrophone} style={{ color: "green" }} />
        );
      } else if (
        props.item.msg.toLowerCase() === "checking devices: camera ok" ||
        props.item.msg.toLowerCase() === "checking devices: camera changed"
      ) {
        return <FontAwesomeIcon icon={faVideo} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase() === "checking devices: microphone ok" ||
        props.item.msg.toLowerCase() === "checking devices: micro changed"
      ) {
        return (
          <FontAwesomeIcon icon={faMicrophone} style={{ color: "green" }} />
        );
      } else if (
        props.item.msg.toLowerCase() === "selecting devices" ||
        props.item.msg.toLowerCase() === "open checking devices"
      ) {
        return <Devices color="orange" />;
      } else if (props.item.msg.toLowerCase() === "close checking devices") {
        return <Devices color="red" />;
      } else if (props.item.msg.toLowerCase() === "setting devices") {
        return <Devices color="green" />;
      } else {
        return <Devices />;
      }
    case "audio":
      if (props.item.msg.toLowerCase() === "js room - mic unmuted") {
        return (
          <FontAwesomeIcon icon={faMicrophone} style={{ color: "green" }} />
        );
      } else {
        return <FontAwesomeIcon icon={faMicrophone} />;
      }

    case "get_blocked_geo":
    case "unblock_country":
    case "blocked countries":
    case "get_countries":
    case "georestrictions":
    case "api mow - get regions list":
      return <FontAwesomeIcon icon={faEarthEurope} />;
    case "add notificacion":
      return <FontAwesomeIcon icon={faBell} />;
    case "management":
      return <FontAwesomeIcon icon={faCrown} />;
    case "otl password":
    case "password_recovery_request":
    case "restorepasswordcontroller":
    case "two factor":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return <FontAwesomeIcon icon={faKey} style={{ color: "green" }} />;
      } else if (props.item.msg.toLowerCase().includes("ko")) {
        return <FontAwesomeIcon icon={faKey} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faKey} />;
      }
    case "set new password":
      return <FontAwesomeIcon icon={faKey} style={{ color: "green" }} />;
    case "banear modelo":
    case "ban end user":
    case "forceadmindisconnect":
      return <FontAwesomeIcon icon={faBan} style={{ color: "red" }} />;
    case "tips":
      return <FontAwesomeIcon icon={faDollarSign} />;
    case "chat":
      if (
        props.item.msg.toLowerCase() === "chat room created" ||
        props.item.msg.toLowerCase() === "create chat room"
      ) {
        return <FontAwesomeIcon icon={faComments} />;
      } else if (
        props.item.msg.toLowerCase() === "chat room destroyed" ||
        props.item.msg.toLowerCase() === "chat - free chat destroyed" ||
        props.item.msg.toLowerCase() === "freechat disconnected" ||
        props.item.msg.toLowerCase() === "chat - free chat disconnected"
      ) {
        return (
          <FontAwesomeIcon icon={faCommentSlash} style={{ color: "red" }} />
        );
      } else {
        return <FontAwesomeIcon icon={faComments} style={{ color: "green" }} />;
      }
    case "favourites":
    case "favorites":
      if (props.item.msg.toLowerCase() === "removefavourite") {
        return <FontAwesomeIcon icon={faHeart} style={{ color: "red" }} />;
      } else if (props.item.msg.toLowerCase() === "addfavourite") {
        return <FontAwesomeIcon icon={faHeart} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faHeart} />;
      }

    case "ko":
    case "failed":
    case "error":
      return <FontAwesomeIcon icon={faTimes} />;
    case "vip":
      if (props.item.msg.toLowerCase() === "requestvip") {
        return <Vip color="orange" />;
      } else if (props.item.msg.toLowerCase().includes("accepted")) {
        return <Vip color="green" />;
      } else if (props.item.msg.toLowerCase().includes("reject")) {
        return <Vip color="red" />;
      } else {
        return <Vip />;
      }

    case "room mode":
      if (props.item.msg.toLowerCase() === "room pause finalized") {
        return <FontAwesomeIcon icon={faPause} style={{ color: "red" }} />;
      } else if (props.item.msg.toLowerCase() === "room paused") {
        return <FontAwesomeIcon icon={faPause} style={{ color: "green" }} />;
      } else {
        return <Mode />;
      }

    case "checksystem":
      return <Devices width="48" />;
    case "room_info":
      if (props.item.msg.toLowerCase() === "selecting devices") {
        return <Devices width="48" color="orange" />;
      } else {
        return "room_info";
      }
    case "toys - kiiroo":
    case "toy":
    case "toys - lovense":
    case "toys":
      if (
        props.item.msg.toLowerCase() === "toy connected" ||
        props.item.msg.toLowerCase() === "app and toy kiiroo connected" ||
        props.item.msg.toLowerCase() === "lovense activated"
      ) {
        return <Toy color="green" />;
      } else if (
        props.item.msg.toLowerCase() === "toy not connected" ||
        props.item.msg.toLowerCase() === "lovense deactivated" ||
        props.item.msg.toLowerCase().includes("error checking") ||
        props.item.msg.toLowerCase() === "disconnect lovense"
      ) {
        return <Toy color="red" />;
      } else if (
        props.item.msg.toLowerCase() === "get auth lovense" ||
        props.item.msg.toLowerCase() === "connect lovense"
      ) {
        return <Toy color="orange" />;
      } else {
        return <Toy />;
      }
    case "home":
      if (props.item.msg.toLowerCase() === "puede emitir") {
        return <Success />;
      } else if (props.item.msg.toLowerCase() === "no puede emitir") {
        return <Failed />;
      } else if (props.item.msg.toLowerCase() === "set priority") {
        return <Priority />;
      } else {
        return <FontAwesomeIcon icon={faInfo} />;
      }
    case "modelalarm":
      return (
        <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "red" }} />
      );
    case "delete gallery":
    case "delete thumb":
    case "rejecting thumb":
    case "uploader pw":
      return <FontAwesomeIcon icon={faPhotoVideo} style={{ color: "red" }} />;
    case "validate gallery":
    case "validating thumb":
    case "photos":
      if (
        props.item.msg.toLowerCase() === "error trying to set profile photo"
      ) {
        return <FontAwesomeIcon icon={faPhotoVideo} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faPhotoVideo} />;
      }
    case "studios":
      return <FontAwesomeIcon icon={faBuilding} />;
    case "add gallery":
      return <FontAwesomeIcon icon={faPhotoVideo} style={{ color: "green" }} />;
    case "get main gallery":
    case "validate thumb":
    case "get thumbs gallery":
    case "set profile photo":
    case "galeria_multimedia":
    case "recorder":
    case "get webcammer galleries":
    case "generate sprite":
    case "generate mosaic":
    case "get profile photo":
    case "videos":
    case "gallery":
      if (
        props.item.msg.toLowerCase() ===
        "el fichero es válido y se subió con éxito."
      ) {
        return <FontAwesomeIcon icon={faUpload} style={{ color: "green" }} />;
      } else if (
        props.item.msg.toLowerCase() === "operation done" ||
        props.item.msg.toLowerCase() === "imagen validada correctamente" ||
        props.item.msg.toLowerCase().includes("uploaded success") ||
        props.item.msg.toLowerCase().includes("upload finish") ||
        props.item.msg.toLowerCase() === "imágenes validadas correctamente"
      ) {
        return (
          <FontAwesomeIcon icon={faPhotoVideo} style={{ color: "green" }} />
        );
      } else if (
        props.item.msg.toLowerCase() === "imagen denegada" ||
        props.item.msg.toLowerCase() === "deleted thumb from gallery"
      ) {
        return <FontAwesomeIcon icon={faPhotoVideo} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faPhotoVideo} />;
      }

    case "whitelabel":
    case "whitebrands":
      if (props.item.msg.toLowerCase() === "clean wl cache") {
        return <FontAwesomeIcon icon={faTag} style={{ color: "red" }} />;
      } else if (props.item.msg.toLowerCase() === "domains cached") {
        return <FontAwesomeIcon icon={faTag} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faTag} />;
      }
    case "discounts":
      if (props.item.status.toLowerCase() === "failed") {
        return <FontAwesomeIcon icon={faTag} style={{ color: "red" }} />;
      } else if (props.item.status.toLowerCase() === "accepted") {
        return <FontAwesomeIcon icon={faTag} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faTag} />;
      }

    case "categories":
      return <FontAwesomeIcon icon={faTags} />;
    case "edit modelo":
    case "add modelo":
    case "profile validate":
    case "validar modelo":
    case "get modelo":
      if (props.item.msg.toLowerCase() === "alta finalizada") {
        return (
          <FontAwesomeIcon icon={faPersonDress} style={{ color: "green" }} />
        );
      } else if (
        props.item.msg.toLowerCase() ===
          "try to add room member in performer registration process" ||
        props.item.msg.toLowerCase().includes("start photos upload progress")
      ) {
        return (
          <FontAwesomeIcon icon={faPersonDress} style={{ color: "orange" }} />
        );
      } else {
        return <FontAwesomeIcon icon={faPersonDress} />;
      }

    case "profile":
      if (props.item.msg.toLowerCase() === "galleries loaded") {
        return <FontAwesomeIcon icon={faPhotoVideo} />;
      } else {
        return <FontAwesomeIcon icon={faInfo} />;
      }
    case "galleries":
      if (props.item.msg.toLowerCase() === "buy gallery - not enough credits") {
        return <FontAwesomeIcon icon={faPhotoVideo} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faPhotoVideo} />;
      }

    case "stop 803 - dtic":
    case "stop prs - dtic":
      return <FontAwesomeIcon icon={faPhoneSlash} style={{ color: "red" }} />;
    case "803 jwt - decode dtic":
    case "prs jwt - decode dtic":
      return <FontAwesomeIcon icon={faPhone} style={{ color: "green" }} />;
    case "locution 803 - dtic":
    case "locution prs - dtic":
    case "sms - dlr dtic":
    case "sms - signup dtic":
      return <FontAwesomeIcon icon={faMobileRetro} />;
    case "sms - signup":
    case "sms simulate":
      if (
        props.item.msg.toLowerCase() === "temporal id not found" ||
        props.item.msg.toLowerCase().includes("already exists")
      ) {
        return (
          <FontAwesomeIcon icon={faMobileRetro} style={{ color: "red" }} />
        );
      } else if (props.item.msg.toLowerCase() === "doi") {
        return (
          <FontAwesomeIcon icon={faMobileRetro} style={{ color: "green" }} />
        );
      } else {
        return (
          <FontAwesomeIcon icon={faMobileRetro} style={{ color: "orange" }} />
        );
      }
    case "sms - dlr":
      if (
        props.item.msg.toLowerCase() === "ticket used" ||
        props.item.msg.toLowerCase() === "invalid parameters" ||
        props.item.msg.toLowerCase().includes("ticket not found")
      ) {
        return (
          <FontAwesomeIcon icon={faMobileRetro} style={{ color: "red" }} />
        );
      } else {
        return (
          <FontAwesomeIcon icon={faMobileRetro} style={{ color: "green" }} />
        );
      }
    case "803 simulate":
      if (props.item.msg.toLowerCase() === "stop call") {
        return <FontAwesomeIcon icon={faPhoneSlash} style={{ color: "red" }} />;
      } else if (props.item.msg.toLowerCase() === "start locution") {
        return <FontAwesomeIcon icon={faPhone} style={{ color: "orange" }} />;
      } else {
        return <FontAwesomeIcon icon={faPhone} style={{ color: "green" }} />;
      }
    case "start 803 - dtic":
    case "start prs - dtic":
    case "prs start - dtic":
      return <FontAwesomeIcon icon={faPhone} style={{ color: "green" }} />;
    case "prs":
      if (props.item.msg.toLowerCase() === "login ok") {
        return <FontAwesomeIcon icon={faUser} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faPhone} style={{ color: "orange" }} />;
      }

    case "traffic notification":
    case "get_voluum_visits":
    case "get_voluum_campaigns_cost":
    case "get url postback":
    case "get_voluum_ts":
    case "get_voluum_campaigns":
    case "get_voluum_conversions":
    case "get_voluum_offers":
      return <img src="/images/voluum.png" height="30" alt="voluum" />;
    case "cron":
    case "cron notify":
      return <FontAwesomeIcon icon={faCalendar} style={{ color: "green" }} />;
    case "dnis":
    case "get dni":
      if (
        props.item.msg.toLowerCase() === "unauthorized" ||
        props.item.msg.toLowerCase() === "validation error"
      ) {
        return <FontAwesomeIcon icon={faIdCard} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faIdCard} />;
      }
    case "delete dni":
      return <FontAwesomeIcon icon={faIdCard} style={{ color: "red" }} />;
    case "upload dni":
      if (props.item.msg.toLowerCase() === "validation error") {
        return <FontAwesomeIcon icon={faIdCard} style={{ color: "red" }} />;
      } else {
        return <FontAwesomeIcon icon={faIdCard} style={{ color: "green" }} />;
      }
    case "olecams roomlists":
    case "getonlinesingleroom":
      return <FontAwesomeIcon icon={faList} />;
    case "stats":
    case "statswebmaster":
    case "phonestats":
    case "adslstats":
    case "visastats":
    case "mobileintstats":
    case "statsolecams":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return (
          <FontAwesomeIcon icon={faChartLine} style={{ color: "green" }} />
        );
      } else {
        return <FontAwesomeIcon icon={faChartLine} />;
      }

    case "resources":
      return <FontAwesomeIcon icon={faScrewdriverWrench} />;
    case "security_middleware":
      return <FontAwesomeIcon icon={faShield} />;
    case "affiliatelogins":
    case "affiliatelogingroups":
    case "affiliate":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return <Affiliates color="green" />;
      } else {
        return <Affiliates />;
      }
    case "landings":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return (
          <FontAwesomeIcon icon={faFileArrowUp} style={{ color: "green" }} />
        );
      } else {
        return <FontAwesomeIcon icon={faFileArrowUp} />;
      }
    case "news":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return (
          <FontAwesomeIcon icon={faNewspaper} style={{ color: "green" }} />
        );
      } else {
        return <FontAwesomeIcon icon={faNewspaper} />;
      }
    case "postbacks":
    case "callbacks":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return <FontAwesomeIcon icon={faLink} style={{ color: "green" }} />;
      } else {
        return <FontAwesomeIcon icon={faLink} />;
      }
    case "banners":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return <Banners color="green" />;
      } else {
        return <Banners />;
      }
    case "contracts":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return <Law color="green" />;
      } else {
        return <Law />;
      }
    case "product":
      if (props.item.msg.toLowerCase().includes("ok")) {
        return <Products color="green" />;
      } else {
        return <Products />;
      }
    case "brokers":
      return <Brokers />;
    case "visausers":
      return <FontAwesomeIcon icon={faCreditCard} />;
    case "creditgift":
      return <FontAwesomeIcon icon={faGift} />;
    case "creditpack":
      return <FontAwesomeIcon icon={faCoins} />;
    case "mautic":
      return <img src="/images/Mautic-logo.png" witdh="32" />;
    case "age verification":
      return <img src="/images/age-18.svg" witdh="24" />;
    case "streamate":
      return <img src="/images/logo_sm.png" witdh="32" />;
    case "affiliates":
      return <FontAwesomeIcon icon={faUsers} />;
    case "fidelity":
      return <FontAwesomeIcon icon={faLink} />;
    case "contents creators":
      return <FontAwesomeIcon icon={faMask} />;
    case "tubetraffic":
      return <img src="/images/tubetraffic.png" witdh="32" />;
    default:
      return props.item.evt.toLowerCase(); //<FontAwesomeIcon icon={faInfo} />;
  }
};

export default LogsSection;
