import React, { useEffect, useState } from "react";
import AlertService from "../../services/alerts-service";
import classNames from "classnames";
import Success from "../icons/success";
import { NavLink } from "react-router-dom";
//style
import "./index.scss";
const OpenAlertsCounter = (props) => {
  const [openAlerts, setOpenAlerts] = useState(null);

  useEffect(() => {
    AlertService.dispatched({ type: "opened" }).then(([res, code]) => {
      console.log(res, code);
      if (code === 200) {
        setOpenAlerts(res.data.length);
      } else {
        setOpenAlerts(0);
      }
    });
  }, []);

  return (
    <div
      className={classNames(
        "card  shadow h-100 py-2",
        { "border-left-warning": openAlerts === null },
        { "border-left-danger": openAlerts > 0 },
        { "border-left-success": openAlerts === 0 }
      )}
    >
      <div className="card-body">
        <NavLink
          exact={true}
          activeClassName="active"
          to="/alerts"
          role="button"
        >
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div
                className={classNames(
                  "text-xs font-weight-bold  text-uppercase mb-1",
                  { "text-warning": openAlerts === null },
                  { "text-danger": openAlerts > 0 },
                  { "text-success": openAlerts === 0 }
                )}
              >
                {openAlerts === 0 && <span>No open alerts</span>}
                {openAlerts === null && <span>Checking alerts</span>}
                {openAlerts !== null && openAlerts !== 0 && (
                  <span>Open alerts</span>
                )}
              </div>

              <div
                className={classNames(
                  "h5 mb-0  ",
                  { "text-warning": openAlerts === null },
                  { "text-danger textOpenAlert": openAlerts > 0 }
                )}
              >
                {openAlerts !== null && openAlerts !== 0 && (
                  <span>{openAlerts}</span>
                )}
                {openAlerts === 0 && <Success width="90" height="90" />}
              </div>
            </div>
            <div className="col-auto">
              <i className="fas fa-comments fa-2x text-gray-300"></i>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default OpenAlertsCounter;
