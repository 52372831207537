import React from "react";

const Banners = (props) => {
  const currentColor = props.color ? props.color : "#000";
  const width = props.width ? props.width : 24;
  const height = props.height ? props.height : 24;
  return (
    <svg
      viewBox="0 0 96 96"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      fill={currentColor}
    >
      <title />
      <path d="M62.4861,29.9994,82.2446,10.2408A6,6,0,0,0,78.0022-.0017H18a5.9968,5.9968,0,0,0-6,6v84.003a6,6,0,0,0,12,0V60H78.0022a6,6,0,0,0,4.2424-10.2425ZM24,48V11.9987H63.5174L49.7591,25.757a5.9979,5.9979,0,0,0,0,8.4847L63.5174,48Z" />{" "}
    </svg>
  );
};

export default Banners;
