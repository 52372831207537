import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../../libs/isAuth";

//Services
import MBService from "../../../services/oc-mb-service";

import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from "../../../components/layout";
import Loading from "../../../components/loading";
import Table from "./table.js";
//style
import "./index.scss";

class Cache extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        items: {},
        total: 0,
      },
      pagination: {
        page: 1,
        perPage: 25,
        lastPage: 1,
      },
      loading: false,
      showAlert: false,
      textAlert: "",
      variantAlert: "success",
    };
    //logger.log("constructor", this.props);
  }
  componentDidMount() {
    //logger.log("componentDidMount", this.props);
    this.getList();
  }

  getList() {
    this.setState({ loading: true });
    MBService.domainsList()
      .then(([response, code]) => {
        this.setState({
          data: response,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.message === "Unauthorized IP, or Origin") {
        }
        this.setState({ loading: false });
      });
  }

  generateDomainsCache = () => {
    this.setState({ loading: true });
    MBService.generateDomainsCache()
      .then(([response, code]) => {
        if (code === 200) {
          this.setState({
            variantAlert: "success",
            textAlert: "Cache de dominios generada con éxito!",
          });
        } else {
          this.setState({
            variantAlert: "danger",
            textAlert: "Se produjo un error generando la cache",
          });
        }
        this.setState({
          loading: false,
          showAlert: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  generateAllMbRedisCache = () => {
    this.setState({ loading: true });
    MBService.generateAllMbsRedisCache()
      .then(([response, code]) => {
        if (code === 200) {
          this.setState({
            variantAlert: "success",
            textAlert: "Cache de marcas blancas generada con éxito!",
          });
        } else {
          if (response.message === 'Not all whitelabels was cached'){
            this.setState({
              variantAlert: "danger",
              textAlert: response.message + '; WL not cached: ' + JSON.stringify(response.wlNotCached)
            });
          }
          
        }
        this.setState({
          loading: false,
          showAlert: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Layout>
        <nav aria-label="breadcrumb">
          <NavLink exact={true} activeClassName="active" to="/" role="button">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Gestión de Cache de Marcas Blancas de Olecams
              </li>
            </ol>
          </NavLink>
        </nav>
        {this.state.loading && <Loading />}

        <div className="row mb-4">
          <div className="col auto">
            <Toast
              className={"bg-" + this.state.variantAlert}
              onClose={() => this.setState({ showAlert: false })}
              show={this.state.showAlert}
              delay={3000}
              animation
              autohide
            >
              <Toast.Header>
                <strong className="mr-auto">Big Brother</strong>
              </Toast.Header>
              <Toast.Body className="text-white">
                {this.state.textAlert}
              </Toast.Body>
            </Toast>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <button className="btn btn-primary mr-2" onClick={this.generateDomainsCache}>
              <FontAwesomeIcon icon={faCheck} className="mr-2" /> Regenerar cache de dominios
            </button>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <button className="btn btn-primary mr-2" onClick={this.generateAllMbRedisCache}>
              <FontAwesomeIcon icon={faCheck} className="mr-2" /> Regenerar cache Redis de todas las Marcas Blancas
            </button>
          </div>
        </div>

        {
          <div className="row justify-content-md-center">
            <div className="col">{<Table data={this.state.data} />}</div>
          </div>
        }
      </Layout>
    );
  }
}

export default withRouter(isAuth()(Cache));
