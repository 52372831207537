import React from "react";
import { withRouter } from "react-router-dom";
import Failed from "../../../components/icons/failed";
import Success from "../../../components/icons/success";
//style
import "./index.scss";

const DataTable = (props) => {
  const methodAndProviderisActivated = (cellMethod, cellProvider, item) => {
    let ok = false;

    item.map((x) => {
      if (cellMethod === x.method) {
        x.providers.map((y) => {
          if (y === cellProvider) {
            ok = true;
          }
        });
      }
    });

    if (ok) return <Success />;
    return <Failed />;
  };
  return (
    <React.Fragment>
      <div className="row mb-5">
        <div className="col">
          {" "}
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              props.regenerate(props.env);
            }}
          >
            Regenerate {props.env}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr className="text-left">
                <th>Providers</th>
              </tr>
            </thead>
            <tbody>
              {props.data.providers.length > 0 && (
                <>
                  {props.data.providers.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={(index + 1) % 2 ? "impar" : "par"}
                      >
                        <td className="text-left">{item.name}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="col">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr className="text-left">
                <th>Methods</th>
              </tr>
            </thead>
            <tbody>
              {props.data.methods.length > 0 && (
                <>
                  {props.data.methods.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={(index + 1) % 2 ? "impar" : "par"}
                      >
                        <td className="text-left">{item.type}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr className="text-left thpayments">
                <th>Country</th>
                {props.data.providers.length > 0 && (
                  <>
                    {props.data.providers.map((item, index) => {
                      return (
                        <th key={index} className="text-left thpayments">
                          {item.name}
                        </th>
                      );
                    })}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {props.data.countries.length > 0 && (
                <>
                  {props.data.countries.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={(index + 1) % 2 ? "impar" : "par"}
                      >
                        <td className="text-left">
                          <small>{item.code}</small>
                        </td>
                        {props.data.providers.length > 0 && (
                          <>
                            {props.data.providers.map((itempro, index2) => {
                              return (
                                <td key={index2} className="text-left">
                                  <span>
                                    {methodAndProviderisActivated(
                                      itempro.method_id,
                                      itempro.id,
                                      item.methods
                                    )}
                                  </span>
                                </td>
                              );
                            })}
                          </>
                        )}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(DataTable);
