import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../../libs/isAuth";

//Services
import BlackListIPService from "../../../services/blacklistip-service";

import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faPlus,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from "../../../components/layout";
import Loading from "../../../components/loading";
import Table from "./table.js";
//style
import "./index.scss";

class BlackListIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        items: {},
        total: 0,
      },
      pagination: {
        page: 1,
        perPage: 25,
        lastPage: 1,
      },
      ip: "0.0.0.0",
      loading: false,
      showAlert: false,
      textAlert: "",
      variantAlert: "success",
    };
    //logger.log("constructor", this.props);
  }
  componentDidMount() {
    //logger.log("componentDidMount", this.props);
    this.getList();
  }

  getList() {
    this.setState({ loading: true });
    BlackListIPService.list()
      .then(([response, code]) => {
        this.setState({
          data: Object.values(response),
          loading: false,
        });
      })
      .catch((error) => {
        if (error.message === "Unauthorized IP, or Origin") {
        }
        this.setState({ loading: false });
      });
  }

  addIP = () => {
    this.setState({ loading: true });
    BlackListIPService.add(this.state.ip)
      .then(([response, code]) => {
        if (code === 200) {
          this.setState({
            variantAlert: "success",
            textAlert: "IP " + this.state.ip + " añadida con éxito!",
          });
        } else {
          this.setState({
            variantAlert: "danger",
            textAlert: "Se produjo un error añadiendo la IP " + this.state.ip,
          });
        }
        this.setState({
          loading: false,
          showAlert: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  checkIP = () => {
    this.setState({ loading: true });
    BlackListIPService.check(this.state.ip)
      .then(([response, code]) => {
        if (response) {
          this.setState({
            variantAlert: "success",
            textAlert: "IP " + this.state.ip + " BANEADA!",
          });
        } else {
          this.setState({
            variantAlert: "danger",
            textAlert: "IP " + this.state.ip + " PERMITIDA!",
          });
        }
        this.setState({
          loading: false,
          showAlert: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  deleteIP = () => {
    if (window.confirm("¿Seguro que quiere eliminar la IP?")) {
      this.setState({ loading: true });
      BlackListIPService.deleteIP(this.state.ip)
        .then(([response, code]) => {
          if (code === 200) {
            this.setState({
              variantAlert: "success",
              textAlert: "IP " + this.state.ip + " borrada!",
            });
          } else {
            this.setState({
              variantAlert: "danger",
              textAlert: "Hubo un problema borrando la IP " + this.state.ip,
            });
          }
          this.setState({
            loading: false,
            showAlert: true,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }
  };

  handleOnChange = (e) => {
    this.setState({ ip: e.target.value });
  };

  paginate = (e) => {
    const { pagination } = this.state;
    pagination.page = e.target.value;
    this.getList();
  };

  handleChangeNumberOfColum = (e) => {
    const { pagination } = this.state;
    pagination.perPage = e.target.value;
    pagination.page = 1;
    this.getList();
  };

  nextPage = (page) => {
    const { pagination } = this.state;
    page++;
    if (page <= pagination.lastPage) {
      pagination.page = page;
      this.getList();
    }
  };

  prevPage = (page) => {
    const { pagination } = this.state;
    page--;
    if (page > 0) {
      pagination.page = page;
      this.getList();
    }
  };

  render() {
    return (
      <Layout>
        <nav aria-label="breadcrumb">
          <NavLink exact={true} activeClassName="active" to="/" role="button">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Gestión de BlackList de IPs
              </li>
            </ol>
          </NavLink>
        </nav>
        {this.state.loading && <Loading />}

        <div className="row mb-4">
          <div className="col auto">
            <Toast
              className={"bg-" + this.state.variantAlert}
              onClose={() => this.setState({ showAlert: false })}
              show={this.state.showAlert}
              delay={3000}
              animation
              autohide
            >
              <Toast.Header>
                <strong className="mr-auto">Big Brother</strong>
              </Toast.Header>
              <Toast.Body className="text-white">
                {this.state.textAlert}
              </Toast.Body>
            </Toast>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-2 col-md-2">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="ip"
                name="ip"
                value={this.state.ip}
                placeholder="0.0.0.0"
                onChange={this.handleOnChange}
              />
            </div>
          </div>
          <div>
            <button className="btn btn-primary mr-2" onClick={this.addIP}>
              <FontAwesomeIcon icon={faPlus} className="mr-2" /> Añadir IP
            </button>
          </div>
          <div>
            <button className="btn btn-info mr-2" onClick={this.checkIP}>
              <FontAwesomeIcon icon={faCheck} className="mr-2" /> Comprobar IP
            </button>
          </div>
          <div>
            <button className="btn btn-danger mr-2" onClick={this.deleteIP}>
              <FontAwesomeIcon icon={faTrash} className="mr-2" /> Borrar IP
            </button>
          </div>
        </div>

        {
          <div className="row justify-content-md-center">
            <div className="col">
              <Table data={this.state.data} />
            </div>
          </div>
        }
      </Layout>
    );
  }
}

export default withRouter(isAuth()(BlackListIP));
