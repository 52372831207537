import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../libs/isAuth";

import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

//  ,
// Components
import Layout from "../../components/layout";
import HomeContent from "./content.js";
//style
import "./index.scss";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ok: 1000,
        ko: 2000,
      },
    };
  }
  componentDidMount = async () => {};
  componentDidUpdate() {}
  render() {
    return (
      <Layout>
        <Breadcrumb>
          <NavLink exact={true} activeClassName="active" to="/" role="button">
            <FontAwesomeIcon icon={faHome} className="mr-2" />
            Home
          </NavLink>
        </Breadcrumb>
        <HomeContent data={this.state.data} />
      </Layout>
    );
  }
}

export default withRouter(isAuth()(Home));
