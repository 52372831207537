import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// API Service
import UserService from "../../../services/user-service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Button, Modal, Form, Row, Col } from "react-bootstrap";

class UpdateUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ID: this.props.data.ID,
        EMAIL: this.props.data.EMAIL,
        USERNAME: this.props.data.USERNAME,
        PWD: "",
        VOLUUM_ACCESS: this.props.data.VOLUUM_ACCESS,
        BB_LEVEL: this.props.data.BB_LEVEL,
      },
    };
  }

  handleSaveUser = async () => {
    const { data } = this.state;

    if (data.PWD === "" || data.PWD === null) {
      this.setState({
        data: {
          ...data,
          PWD: "",
        },
      });
    }

    UserService.update(data)
      .then(([response, code]) => {
        console.log(response, code);
        if (code === 200) {
          this.setState({
            loading: false,
          });
          this.props.handleClose();
        } else {
          this.setState({
            showAlert: true,
            alertText: "Error updating user",
            error: true,
            alertSeverity: "info", // error, warning, info, success
          });
        }
      })
      .catch((error) => {
        if (error.message === "Unauthorized IP, or Origin") {
          this.setState({
            showAlert: true,
            alertText: error.message,
            error: true,
            alertSeverity: "info", // error, warning, info, success
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleOnChange = (e) => {
    const { value, name } = e.target;
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });

    if (name === "BB_LEVEL") {
      this.setState({
        data: {
          ...data,
          [name]: e.target.checked ? 0 : 1,
        },
      });
    }
    if (name === "VOLUUM_ACCESS") {
      this.setState({
        data: {
          ...data,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    }
  };

  render() {
    const { data } = this.state;
    //this.setState({ data: this.props.data})
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row}>
              <Form.Label column>Username</Form.Label>
              <Col xs lg="8">
                <Form.Control
                  name="USERNAME"
                  type="text"
                  placeholder="Username"
                  onChange={this.handleOnChange}
                  value={data.USERNAME}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column>Email</Form.Label>
              <Col xs lg="8">
                <Form.Control
                  name="EMAIL"
                  type="text"
                  placeholder="Email"
                  onChange={this.handleOnChange}
                  value={data.EMAIL}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column>Password</Form.Label>
              <Col xs lg="8">
                <Form.Control
                  name="PWD"
                  type="password"
                  placeholder="Password"
                  onChange={this.handleOnChange}
                  value={data.PWD}
                />
              </Col>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="BB_LEVEL" className="text-center">
                {data.BB_LEVEL === 1 ? (
                  <Form.Check
                    name="BB_LEVEL"
                    type="checkbox"
                    label="Admin"
                    onChange={this.handleOnChange}
                  />
                ) : (
                  <Form.Check
                    name="BB_LEVEL"
                    type="checkbox"
                    label="Admin"
                    onChange={this.handleOnChange}
                    checked
                  />
                )}
              </Form.Group>

              <Form.Group
                as={Col}
                controlId="VOLUUM_ACCESS"
                className="text-center"
              >
                {data.VOLUUM_ACCESS === 1 ? (
                  <Form.Check
                    name="VOLUUM_ACCESS"
                    type="checkbox"
                    label="Voluum"
                    onChange={this.handleOnChange}
                    checked
                  />
                ) : (
                  <Form.Check
                    name="VOLUUM_ACCESS"
                    type="checkbox"
                    label="Voluum"
                    onChange={this.handleOnChange}
                  />
                )}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Cerrar
          </Button>
          <Button variant="success" onClick={(e) => this.handleSaveUser()}>
            <FontAwesomeIcon icon={faCheck} className="mr-2" /> Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(UpdateUserModal);
