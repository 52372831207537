import React from "react";

const Toy = (props) => {
  const currentColor = props.color ? props.color : "#000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path
        fill={currentColor}
        d="M16.113 2.565c-.422.195-1.526.508-4.633.099-6.116-.806-15.187 6.47-7.59 10.962 3.34 1.974 7.762-2.03 10.922.887 1.752 1.618 2.015 4.387.105 4.985C2.938 23.25-9.356 4.892 10.46.516c.922-.203 1.903-.482 2.979-.514 2.152-.065 5.044 1.471 2.675 2.563zM18 4.197L19.3 3C20.968 4.536 22 6.658 22 9c0 2.219-.926 4.24-2.442 5.753L19.3 15 18 13.803c1.336-1.222 2.157-2.919 2.157-4.803 0-1.765-.727-3.38-1.927-4.583l-.23-.22L19.3 3 18 4.197zM17.334 5C18.366 6.028 19 7.443 19 9s-.634 2.972-1.666 4L16 11.67c.681-.68 1.107-1.623 1.107-2.67S16.681 7.01 16 6.33L17.334 5z"
      />
    </svg>
  );
};

export default Toy;
