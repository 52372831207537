import React, { useState, useEffect } from "react";
import UserService from "../../services/user-service";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
//libs
import { setAuth, removeAuth } from "../../libs/cookies";
import Loading from "../../components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import DevbugModal from "./devbugModal";
//style
import "./index.scss";

const Login = (props) => {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [devbug, setDevbug] = useState(false);
  const [error, setError] = useState(false);
  // on component mount
  useEffect(() => {}, []);

  const handleChange = (e) => {
    let input = e.target.getAttribute("name");
    let value = e.target.value;

    if (e.target.value === "devbug") {
      e.target.value = "";
      setUser("");
    } else {
      if (input === "user") {
        setUser(value);
        //  this.setState({ user: value });
      } else if (input === "pwd") {
        setPwd(value);
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (user !== "" && pwd !== "") {
      const [response, code] = await UserService.login(user, pwd);
      if (code === 200) {
        setAuth(response);
        setError(false);
        setErrorMsg("");
        setRedirect(true);
      } else {
        setError(true);
        setErrorMsg(response.error);
        removeAuth();
        setLoading(false);
      }
    } else {
      setError(true);
      setErrorMsg("You must fill user and password");
      setLoading(false);
    }
  };

  const closeModal = () => setDevbug(false);
  return (
    <body className="bodyLogin">
      {redirect && <Redirect to="/home" />}
      {devbug && <DevbugModal show={true} closeModal={closeModal} />}
      {loading && <Loading />}

      <form className="form-signin text-center" onSubmit={submit}>
        <div className="text-center border-1">
          <img
            className="mb-4 mr-5"
            src="/images/Marca.png"
            alt=""
            width="370"
          />
        </div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {errorMsg}
          </div>
        )}
        <label for="inputEmail" class="sr-only">
          Email address
        </label>
        <input
          type="text"
          className={`form-control ${error ? "is-invalid" : ""}`}
          name="user"
          value={user}
          aria-describedby="emailHelp"
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <label for="inputPassword" className="sr-only">
          Password
        </label>
        <input
          type="password"
          className={`form-control ${error ? "is-invalid" : ""}`}
          value={pwd}
          name="pwd"
          onChange={handleChange}
          placeholder="Password"
          required
        />

        <button className="btn btn-lg btn-primary btn-block mt-4">
          Sign In
        </button>
        <p className="mt-2 mb-3 text-center">
          {" "}
          <NavLink
            exact={true}
            activeClassName="active"
            to="/requestpwd"
            role="button"
          >
            Password recovery
          </NavLink>
        </p>
      </form>
    </body>
  );
};
export default Login;
