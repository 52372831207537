import request from "./api-service";

function login(user, pwd) {
  return request({
    url: `/login`,
    method: "POST",
    data: { user: user, pwd: pwd },
  });
}

function logout() {
  return request({
    url: `/logout`,
    method: "POST",
  });
}

function me() {
  return request({
    url: `/me`,
    method: "GET",
  });
}

function list() {
  return request({
    url: `/admin/user/list`,
    method: "GET",
  });
}

function set(data) {
  return request({
    url: `/admin/user`,
    method: "POST",
    data,
  });
}

function requestpass(email) {
  return request({
    url: `/requestpass`,
    method: "POST",
    data: { email },
  });
}
function regenerate(pwd1, pwd2, token) {
  return request({
    url: `/resetpassword`,
    method: "POST",
    data: { pwd1, pwd2, token },
  });
}
function update(data) {
  return request({
    url: `/admin/user`,
    method: "PUT",
    data,
  });
}

function deleteUser(id) {
  return request({
    url: `/admin/user/${id}`,
    method: "DELETE",
  });
}

const UserService = {
  login,
  logout,
  me,
  list,
  set,
  update,
  deleteUser,
  requestpass,
  regenerate,
};

export default UserService;
