import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// API Service
import UserService from "../../../services/user-service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Button, Modal, Form, Row, Col } from "react-bootstrap";

class CreateUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        EMAIL: "",
        USERNAME: "",
        PWD: "",
        BB_LEVEL: 1,
        VOLUUM_ACCESS: 0,
      },
    };
  }

  componentDidMount() {}

  handleSaveUser = async () => {
    UserService.set(this.state.data)
      .then((response) => {
        this.setState({
          loading: false,
        });
        this.props.handleClose();
      })
      .catch((error) => {
        if (error.message === "Unauthorized IP, or Origin") {
          this.setState({
            showAlert: true,
            alertText: error.message,
            error: true,
            alertSeverity: "info", // error, warning, info, success
          });
        }
        this.setState({
          loading: false,
        });
      });
  };

  handleOnChange = (e) => {
    const { value, name } = e.target;
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });

    if (name === "BB_LEVEL") {
      this.setState({
        data: {
          ...data,
          [name]: e.target.checked ? 0 : 1,
        },
      });
    }
    if (name === "VOLUUM_ACCESS") {
      this.setState({
        data: {
          ...data,
          [name]: e.target.checked ? 1 : 0,
        },
      });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row}>
              <Form.Label column>Username</Form.Label>
              <Col xs lg="8">
                <Form.Control
                  name="USERNAME"
                  type="text"
                  placeholder="Username"
                  onChange={this.handleOnChange}
                  value={data.USERNAME}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column>Email</Form.Label>
              <Col xs lg="8">
                <Form.Control
                  name="EMAIL"
                  type="text"
                  placeholder="Email"
                  onChange={this.handleOnChange}
                  value={data.EMAIL}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column>Password</Form.Label>
              <Col xs lg="8">
                <Form.Control
                  name="PWD"
                  type="password"
                  placeholder="Password"
                  onChange={this.handleOnChange}
                  value={data.PWD}
                />
              </Col>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="BB_LEVEL" className="text-center">
                <Form.Check
                  name="BB_LEVEL"
                  type="checkbox"
                  label="Admin"
                  onChange={this.handleOnChange}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                controlId="VOLUUM_ACCESS"
                className="text-center"
              >
                <Form.Check
                  name="VOLUUM_ACCESS"
                  type="checkbox"
                  label="Voluum"
                  onChange={this.handleOnChange}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Close
          </Button>
          <Button variant="success" onClick={(e) => this.handleSaveUser()}>
            <FontAwesomeIcon icon={faCheck} className="mr-2" /> Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(CreateUserModal);
