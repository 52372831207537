import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../libs/isAuth";
import { getAuth } from "../../libs/cookies";
import { Breadcrumb, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

// Components
import Layout from "../../components/layout";

//style

class Documentation extends Component {
  constructor(props) {
    super(props);
    const auth = getAuth();
    console.log(auth);
    this.state = {
      token: auth.token,
    };
  }
  componentDidMount() {}
  componentDidUpdate() {}
  render() {
    return (
      <Layout>
        <Breadcrumb>
          <NavLink exact={true} activeClassName="active" to="/" role="button">
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            Documentation
          </NavLink>
        </Breadcrumb>
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr className="text-center">
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                <a
                  target="blank"
                  href={`https://devapibigbrother.invertred.com/api/documentation?token=${this.state.token}`}
                >
                  Big Brother API
                </a>
              </td>
            </tr>
            <tr>
              <td className="text-center">
                <a
                  target="blank"
                  href="https://api.modelsonwebcam.com/documentation"
                >
                  MOW API
                </a>
              </td>
            </tr>
            <tr>
              <td className="text-center">
                <a
                  target="blank"
                  href="https://apiv2.olecams.com/documentation"
                >
                  OC V2 API
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </Layout>
    );
  }
}

export default withRouter(isAuth()(Documentation));
