import React from "react";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Success from "../../components/icons/success";
import Failed from "../../components/icons/failed";
const AlertStatus = (props) => {
  const { status } = props;
  let result;

  switch (status) {
    case 0: // Block
      result = (
        <div className="handed" onClick={(e) => props.click(props.id)}>
          <Failed />
        </div>
      );
      break;
    case 1: // Active
      result = <Success />;
      break;
    default:
      // Active
      result = <FontAwesomeIcon icon={faCheck} className="mr-2" />;
      break;
  }
  return <>{result}</>;
};

export default AlertStatus;
