import React, { useEffect, useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../../libs/isAuth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
//Services
import PaymentsService from "../../../services/payments-service";
import Layout from "../../../components/layout";
import Table from "./table.js";
import Loading from "../../../components/loading";
const Payments = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataStaging, setDataStaging] = useState(null);
  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    setLoading(true);
    PaymentsService.getPaymentsProviders("production")
      .then(([response, code]) => {
        console.log(response);
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
    PaymentsService.getPaymentsProviders("staging")
      .then(([response, code]) => {
        console.log(response);
        setDataStaging(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const regenerate = (env) => {
    let rebootCode = prompt("Regeneration code", "");

    if (rebootCode === "") return;
    setLoading(true);

    PaymentsService.regeneratePayments(env, rebootCode)
      .then(([response, code]) => {
        console.log(response, code);
        if (code === 200) {
          setDataStaging(response);
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <NavLink exact={true} activeClassName="active" to="/" role="button">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
              Payments
            </li>
          </ol>
        </NavLink>
      </nav>
      {loading && <Loading />}
      <div className="row">
        <div className="col-6">
          {data && (
            <Table data={data} env="production" regenerate={regenerate} />
          )}
        </div>
        <div className="col-6">
          {dataStaging && (
            <Table data={dataStaging} env="staging" regenerate={regenerate} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(isAuth()(Payments));
