import React, { Component } from "react";
import Success from "../../icons/success";
import Failed from "../../icons/failed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
//style
import "../index.scss";
class Logstatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderStatus = (st) => {
    if (typeof st !== "string")
      return <FontAwesomeIcon icon={faInfoCircle} className="blue" />;
    switch (st.toLowerCase()) {
      case "ok":
      case "success":
      case "accepted":
        return <Success />;
      case "ko":
      case "failed":
      case "error":
        return <Failed />;
      case "warn":
      case "warning":
        return (
          <FontAwesomeIcon icon={faExclamationTriangle} className="yellow" />
        );
      case "info":
      case "highlight":
        return <FontAwesomeIcon icon={faInfoCircle} className="blue" />;
      default:
        return <span>{st}</span>;
    }
  };
  render() {
    return this.renderStatus(this.props.status);
  }
}

export default Logstatus;
