import React, { useState, useEffect } from "react";
import UserService from "../../services/user-service";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

import Loading from "../../components/loading";

//style
import "./index.scss";

const Recovery = (props) => {
  const params = useParams();
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  // on component mount

  const handleChange = (e) => {
    let input = e.target.getAttribute("name");
    let value = e.target.value;

    if (input === "pwd1") {
      setPwd1(value);
    } else if (input === "pwd2") {
      setPwd2(value);
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    if (pwd1 !== "" && pwd2 !== "") {
      if (pwd1 !== pwd2) {
        setErrorMsg("Passwords must be the same");
        setError(true);
        return;
      } else {
        setError(false);
      }
      setLoading(true);
      const [response, code] = await UserService.regenerate(
        pwd1,
        pwd2,
        params.token
      );
      console.log(response, code);
      if (code === 200) {
        setSuccess(true);
        setLoading(false);
        setError(false);
        setErrorMsg("");
      } else {
        setError(true);
        setErrorMsg(response.error);
        setLoading(false);
      }
    } else {
      setError(true);
      setErrorMsg("You must fill both passwords");
      setLoading(false);
    }
  };

  return (
    <div className="loginLayout-container">
      {loading && <Loading />}
      <main className="form-signin">
        <div className="shadow-lg border-0 rounded-lg mt-5 card">
          <div className="card-header">
            <h4 className="text-center font-weight-light my-2">
              Set new password
            </h4>
          </div>
          <div className="card-body">
            {success && (
              <div className="alert alert-success" role="alert">
                Password reset successfully
                <div className="text-center">
                  <NavLink
                    exact={true}
                    activeClassName="active"
                    to="/"
                    role="button"
                  >
                    Login now
                  </NavLink>
                </div>
              </div>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}
            {!success && (
              <form onSubmit={submit}>
                <div className="form-group">
                  <input
                    type="password"
                    className={`form-control ${error ? "is-invalid" : ""}`}
                    name="pwd1"
                    value={pwd1}
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    placeholder="Password"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={`form-control ${error ? "is-invalid" : ""}`}
                    value={pwd2}
                    name="pwd2"
                    onChange={handleChange}
                    placeholder="Repeat password"
                    required
                  />
                </div>
                <div className="form-group">
                  <button
                    // type="button"
                    className="btn btn-primary w-100 btn btn-lg"
                    // onClick={submit}
                  >
                    Regenerate
                  </button>
                </div>
                <div className="form-group">
                  <div className="text-center">
                    <NavLink
                      exact={true}
                      activeClassName="active"
                      to="/"
                      role="button"
                    >
                      Back
                    </NavLink>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="card-footer">
            <div className="text-center">
              <img
                className="mb-4 mr-5"
                src="/images/Marca.png"
                alt=""
                width="300"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Recovery;
