import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import AlertService from "../../../services/alerts-service";
// Components
import Layout from "../../../components/layout";
import Loading from "../../../components/loading";
import Table from "./table.js";
//style
import "./index.scss";
import CreateAlertModal from "./createAlertModal";
import AlertUsers from "./alertUsers";

//style
import "./index.scss";

const AlertsManagement = (props) => {
  const [data, setData] = useState({});
  const [update_data, setUpdate_data] = useState({});
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [type] = useState("all");
  const types = ["global", "olecams", "vce", "mow", "oletraffic", "critical"];
  // on component mount
  useEffect(() => {
    getAlertsEnabled();
    getList();
  }, []);
  const handleShowModal = () => {
    setUpdate_data({});
    setShowModal(true);
  };

  const getAlertsEnabled = async () => {
    const [response, code] = await AlertService.getAlertsEnabled();
    if (code === 200) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  const handleShowUsers = async (id) => {
    const [response, code] = await AlertService.getAlert(id);

    if (code === 200) {
      setUpdate_data(response);
      setShowUsers(true);
    }
  };
  const handleSaveAlert = () => {};
  const deleteAlert = async (id) => {
    if (window.confirm("Do you want to delete this alert?")) {
      setLoading(true);
      const [response, code] = await AlertService.deleteAlert(id);
      if (code === 200) {
        getList();
      } else {
        toast.error(`Alert failed - ${response.error}`);
        console.error(response, code);
        setLoading(false);
      }
    }
  };
  const getAlert = async (id) => {
    const [response, code] = await AlertService.getAlert(id);

    if (code === 200) {
      setUpdate_data(response);
      setShowModal(true);
    }
  };
  const dispatchAlert = async (id) => {
    setLoading(true);
    const [response, code] = await AlertService.dispatchone(id);
    if (code === 200) {
      const { shouldDispatch, action } = response.message;
      if (shouldDispatch && action === "open") {
        toast.success("Alert dispatched");
      } else if (shouldDispatch && action === "none") {
        toast.info("Alert already dispatched");
      } else if (!shouldDispatch && action === "close") {
        toast.info("Alert closed");
      } else if (!shouldDispatch) {
        toast.warn("No reason to dispatch alert");
      }
    } else {
      toast.error(`Alert failed - ${response.error}`);
      console.error(response, code);
    }
    setLoading(false);
  };

  const getList = async (type, status) => {
    setLoading(true);
    if (typeof type === "undefined") type = "all";
    const [response, code] = await AlertService.list(type, status);
    if (code === 200) {
      setData(response);
      setLoading(false);
    } else {
      if (response.message === "Unauthorized IP, or Origin") {
        this.setState({
          showAlert: true,
          alertText: response.message,
          error: true,
          alertSeverity: "info", // error, warning, info, success
        });
      }
      setLoading(false);
    }
  };
  const handleClose = () => {
    setShowModal(false);
    setUpdate_data({});
    setShowUsers(false);
    getList();
  };
  const handleOnChange = (e) => {
    const { value } = e.target;
    getList(value);
  };

  const handleDisableAlerts = async (value) => {
    // const { value } = e.target;

    const [response, code] = await AlertService.setAlertEnabled(value);
    if (code === 200) {
      if (value === 1) {
        toast.success(`Alerts enabled`);
      } else {
        toast.warn(`Alerts disabled`);
      }

      setEnabled(value);
    } else {
      toast.error(`Error enabling/disabling alerts - ${response.error}`);
    }
  };

  /*  ,
  setAlertEnabled,*/
  return (
    <Layout>
      <Breadcrumb>
        <NavLink exact={true} activeClassName="active" to="/" role="button">
          <FontAwesomeIcon icon={faBell} className="mr-2" />
          Alert Management
        </NavLink>
      </Breadcrumb>
      {loading && <Loading />}
      <div className="row mb-4">
        <div className="col-2 col-md-2">
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShowModal}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Create
            </button>
          </div>
        </div>

        <div className="col-6 col-md-6 ">
          <div className="form-inline">
            <label className="mr-2">Filter by type</label>
            <select
              className="form-control"
              name="type"
              id="type"
              onChange={handleOnChange}
              defaultValue={type}
            >
              <option key="all" value="all">
                All
              </option>
              {types.length > 0 &&
                types.map((number) => {
                  return (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-4 text-right">
          {enabled && (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => {
                  handleDisableAlerts(0);
                }}
              >
                Disable alerts
              </button>
            </div>
          )}
          {!enabled && (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-success"
                onClick={(e) => {
                  handleDisableAlerts(1);
                }}
              >
                Enable alerts
              </button>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <CreateAlertModal
          show={true}
          data={update_data}
          handleClose={handleClose}
          handleSaveAlert={handleSaveAlert}
          types={types}
        />
      )}
      {showUsers && (
        <AlertUsers
          show={true}
          data={update_data}
          handleClose={handleClose}
          handleSaveAlert={handleSaveAlert}
        />
      )}
      {!enabled && (
        <div className="row ml-1">
          <div className="alert alert-danger" role="alert">
            Alerts are disabled!
          </div>
        </div>
      )}
      <Table
        data={data}
        deleteAlert={deleteAlert}
        getAlert={getAlert}
        showUsers={handleShowUsers}
        dispatchAlert={dispatchAlert}
      />
    </Layout>
  );
};
export default AlertsManagement;
