import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import AlertService from "../../services/alerts-service";
// Components
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import Table from "./table.js";
//style
import "./index.scss";

const Alerts = (props) => {
  const [data, setData] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    type: "all", //"opened",
    pagination: {
      page: "1",
      items: "50",
    },
  });

  // on component mount
  useEffect(() => {
    getStatus();
    getList();
  }, []);

  // on change filters
  useEffect(() => {
    getList();
  }, [filters]);

  const closeAlert = async (id) => {
    if (window.confirm("Are you sure you want to close this alert?")) {
      setLoading(true);
      const [response, code] = await AlertService.closeAlert(id);
      if (code === 200) {
        await getList();
      } else {
        console.error(response, code);
        setLoading(false);
      }
    }
  };
  const getStatus = () => {
    AlertService.getAlertsEnabled().then(([response, code]) => {
      if (code === 200) {
        setEnabled(true);
      } else {
        toast.warn("Alerts are disabled!!");
        setEnabled(false);
      }
    });
  };
  const getList = useCallback(() => {
    setLoading(true);
    AlertService.dispatched(filters).then(([response, code]) => {
      if (code === 200) {
        setData(response);
        setLoading(false);
      } else {
        toast.error("Error obtaing alert list");
        setLoading(false);
      }
    });
  }, [filters, loading]);

  const handleFilters = (value) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        type: value,
      };
    });
  };

  const handleChangeNumberOfColum = async (pages) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        pagination: { items: pages, page: 1 },
      };
    });
  };

  const nextPage = async (page) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        pagination: {
          items: prevFilters.pagination.items,
          page: page,
        },
      };
    });
  };

  const prevPage = async (page) => {
    /*const { filters } = this.state;
    filters.pagination.page = page;
    this.setState({ filters }, () => {
      this.getLogs();
    });*/
  };

  return (
    <Layout>
      <Breadcrumb>
        <NavLink exact={true} activeClassName="active" to="/" role="button">
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
          Alerts
        </NavLink>
      </Breadcrumb>
      {loading && <Loading />}
      {!enabled && (
        <div className="row ml-1">
          <div className="alert alert-danger" role="alert">
            Alerts are disabled!
          </div>
        </div>
      )}
      {data && (
        <Table
          data={data}
          enabled={enabled}
          closeAlert={closeAlert}
          prevPage={prevPage}
          nextPage={nextPage}
          filters={filters}
          handleFilters={handleFilters}
          handleChangeNumberOfColum={handleChangeNumberOfColum}
        />
      )}
    </Layout>
  );
};
export default Alerts;
