import request from "./api-service";

function getPaymentsProviders(env) {
  let baseurl = `admin/payments/providers/${env}`;
  return request({
    url: baseurl,
    method: "GET",
  });
}

function regeneratePayments(env, code) {
  return request({
    url: `admin/payments/regenerate`,
    method: "POST",
    data: { env, code },
  });
}

const PaymentsService = {
  getPaymentsProviders,
  regeneratePayments,
};

export default PaymentsService;
