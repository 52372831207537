import React from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faList,
  faUserCog,
  faBell,
  faEnvelope,
  faNetworkWired,
  faCreditCard,
  faDatabase,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

class AdminSubMenu extends React.Component {
  render() {
    return (
      <>
        <hr />
        <p className="ml-3">
          <FontAwesomeIcon icon={faUserCog} className="mr-2" />
          ADMIN OPTIONS
        </p>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/blacklistip"
            role="button"
          >
            <FontAwesomeIcon icon={faList} className="mr-2" />
            BlackList IPs
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/blacklistemails"
            role="button"
          >
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            BlackList Emails
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/websockets"
            role="button"
          >
            <FontAwesomeIcon icon={faNetworkWired} className="mr-2" />
            Websockets
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/payments"
            role="button"
          >
            <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
            Payments
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/database"
            role="button"
          >
            <FontAwesomeIcon icon={faDatabase} className="mr-2" />
            Database
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/user"
            role="button"
          >
            <FontAwesomeIcon icon={faUsers} className="mr-2" />
            Users
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/alerts"
            role="button"
          >
            <FontAwesomeIcon icon={faBell} className="mr-2" />
            Alert Management
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/cache"
            role="button"
          >
            <FontAwesomeIcon icon={faServer} className="mr-2" />
            Cache
          </NavLink>
        </Nav.Item>
        <Nav.Item className="nav-link">
          <NavLink
            exact={true}
            activeClassName="active"
            to="/admin/brokers"
            role="button"
          >
            <FontAwesomeIcon icon={faUsers} className="mr-2" />
            Brokers
          </NavLink>
        </Nav.Item>
      </>
    );
  }
}

export default AdminSubMenu;
