import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
//import UserService from "../../services/user-service";
//style
import "./index.scss";
//actions

import isAuth from '../../libs/isAuth'
//libs
import { removeAuth } from '../../libs/cookies';
import SideBar from "../sidebar/SideBar";
import Content from "../content/Content";

class Layout extends Component {
    constructor (props) {
        super(props)
        this.state = {
            headerHeight: 0,
            footerHeight: 0,
            showMenu: true,
            showSidebar: true,
            showUserMenu: true,
            isOpen: false,
            isMobile: true
        }
        this.previousWidth = -1;
    }

    updateWidth() {
      const width = window.innerWidth;
      const widthLimit = 576;
      const isMobile = width <= widthLimit;
      const wasMobile = this.previousWidth <= widthLimit;

      if (isMobile !== wasMobile) {
        this.setState({
          isOpen: !isMobile
        });
      }

      this.previousWidth = width;
    }

    /**
     * Add event listener
     */
    componentDidMount() {
      this.updateWidth();
      window.addEventListener("resize", this.updateWidth.bind(this));
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWidth.bind(this));
    }

    toggle = () => {
      this.setState({ isOpen: !this.state.isOpen });
    };

    logoutUser = () => {
        if(window.confirm("¿Cerrar sesión?")){
            removeAuth();
            this.props.history.push("/login")
            /*UserService.logout().then(res => {
                if(res.message !== "logout OK") {
                    removeAuth();
                    this.props.history.push("/login")
                }
            })*/
        }
    }
  render() {
    return (
        <div className="d-flex wrapper" id="wrapper">
            <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
            <Content
                toggle={this.toggle}
                isOpen={this.state.isOpen}
                content={this.props.children}
                logoutUser={this.logoutUser}
            />
        </div>
    );
  }
}

export default withRouter(((isAuth()(Layout))))
