import request from "./api-ot-service";

function generateBrokerFiles() {
  return request({
    url: `/brokers/creatembfile`,
    method: "GET",
  });
}

const BrokersService = {
  generateBrokerFiles,
};

export default BrokersService;
