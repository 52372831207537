import React from "react";

const Devices = (props) => {
  const currentColor = props.color ? props.color : "#000";
  const width = props.width ? props.width : 24;
  const height = props.height ? props.height : 24;
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      fill={currentColor}
    >
      <title />
      <g data-name="Layer 2" id="Layer_2">
        <path d="M11,17.93V20H8v2h8V20H13V17.93a8,8,0,1,0-2,0ZM12,6a4,4,0,1,1-4,4A4,4,0,0,1,12,6Z" />
        <circle cx="12" cy="10" r="2" />
      </g>
    </svg>
  );
};

export default Devices;
