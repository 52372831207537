import cookie from "react-cookies";
import { setBaseURL, setAuthToken } from "../services/api-service";

export const setAuth = (auth) => {
  //match expiration to api's exp
  const expires = new Date(auth.exp * 1000);
  expires.setTime(expires.getTime());
  cookie.save(
    "auth",
    { token: auth.token, level: auth.level, username: auth.username },
    {
      path: "/",
      expires: expires,
    }
  );
  setAuthToken(auth.token);
};

export const setDevBugCookie = (data) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save("devbug", data, {
    path: "/",
    expires: expires,
  });
  setBaseURL(data);
};

export const getAuth = () => {
    if (cookie.load("auth")){
        setAuthToken(cookie.load("auth").token);
        return cookie.load("auth");
    } else {
        return null;
    }
}

export const getDevBugCookie = () => cookie.load("devbug", true);

export const removeAuth = () => {
    cookie.remove("auth", { path: "/" });
    setAuthToken();
}
