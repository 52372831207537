/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 * @link    https://gist.github.com/sheharyarn/7f43ef98c5363a34652e60259370d2cb
 */

import axios from "axios";

/**
 * Create an Axios Client with defaults
 */

const client = axios.create({
  baseURL: process.env.REACT_APP_OC_API_URL,
  headers: {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": `Bearer ${process.env.REACT_APP_OC_API_BEARER_TOKEN}`
  },
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  //client.defaults.headers = options.headers;
  //console.log(options.headers);
  const onSuccess = function (response) {
    //console.debug("Request Successful!", response);
    return [response.data.message, 200];
  };

  const onError = function (error) {
    //console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      /*console.error("Status:", error.response.status);
      console.error("Data:", error.response.message);
      console.error("Headers:", error.response.headers);*/
    } else {
      // Something else happened while setting up the request
      // triggered the error
      //console.error("Error Message:", error.message);
    }
    try {
      return [error.response.data, error.response.data.code];
    } catch (e) {
      return [e, 500];
    }
  };

  return client(options).then(onSuccess).catch(onError);
};

/*export function setAuthToken(token = null) {
  client.defaults.headers["Authorization"] = "";
  delete client.defaults.headers["Authorization"];

  if (token) {
    client.defaults.headers["Authorization"] = `Bearer ${process.env.REACT_APP_OC_API_BEARER_TOKEN}`;
  }
}*/

/*export function setBaseURL(baseURL) {
  client.defaults.baseURL = baseURL;
}*/

export default request;
