import request from "./api-oc-service";

function domainsList() {
  return request({
    url: `/wl/cache/domains`,
    method: "GET",
  });
}

function generateDomainsCache() {
  return request({
    url: `/wl/cache/domains`,
    method: "PUT",
    //data: { ip },
  });
}

function generateAllMbsRedisCache() {
  return request({
    url: `/wl/cache/rebuildall`,
    method: "POST",
    //data: { ip },
  });
}

const MBService = {
  domainsList,
  generateDomainsCache,
  generateAllMbsRedisCache
};

export default MBService;
