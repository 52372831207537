import React, { Component } from "react";
import { Link } from "react-router-dom";
//style
import "./index.scss";

class Footer extends Component {
  render() {
    return (
        <footer className="page-footer font-small blue py-4 mt-auto">
            <div className="footer-copyright text-center py-3 small">
              {'Copyright © '}
              <Link color="inherit" to="https://promocionesweb.com/">PromocionesWeb 2016, S.L.</Link>
              {' '}{new Date().getFullYear()}{'. '}
          </div>
        </footer>
    );
  }
}
export default Footer;
