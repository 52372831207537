import request from "./api-service";

function getLogs(bucket, filters, date_ini, date_end, pagination) {
  let req = `/logs/${bucket}?`;
  if (pagination) {
    if (pagination.page) req = `${req}&page=${pagination.page}`;
    if (pagination.items) req = `${req}&items=${pagination.items}`;
  }
  if (date_ini) req = `${req}&from=${date_ini}`;
  if (date_end) req = `${req}&to=${date_end}`;
  for (let key in filters) {
    if (key !== "pagination" && filters[key])
      req = `${req}&${key}=${filters[key]}`;
  }
  return request({
    url: req,
    method: "GET",
  });
}

function getOneLog(bucket, id) {
  return request({
    url: `/logs/${bucket}/${id}`,
    method: "GET",
  });
}

const LogsService = {
  getLogs,
  getOneLog,
};
export default LogsService;
