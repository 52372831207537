import React, { useEffect, useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import isAuth from "../../../libs/isAuth";
import HealthService from "../../../services/health-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import Table from "./table.js";
import { toast } from "react-toastify";
import Layout from "../../../components/layout";
const Database = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // on component mount
  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    setLoading(true);
    HealthService.getDatabaseBackup("production")
      .then(([response, code]) => {
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <NavLink exact={true} activeClassName="active" to="/" role="button">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <FontAwesomeIcon icon={faDatabase} className="mr-2" />
              Database
            </li>
          </ol>
        </NavLink>
      </nav>
      <div className="row justify-content-md-center">
        <div className="col">
          <Table data={data} />
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(isAuth()(Database));
