import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faDatabase,
  faUser,
  faTimes,
  faBook,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { Nav, Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { getAuth } from "../../libs/cookies";

import SubMenuLogs from "./SubMenuLogs";
import AdminSubMenu from "./AdminSubMenu";

import "./index.scss";

class SideBar extends React.Component {
  render() {
    return (
      <div className={classNames("sidebar", { "is-open": this.props.isOpen })}>
        <div className="sidebar-header">
          <Button
            variant="link"
            onClick={this.props.toggle}
            style={{ color: "#fff" }}
            className="mt-4"
          >
            <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
          </Button>
          <Container fluid className="text-center p-2">
            <img src="/images/marca.svg" alt="Logo" className="img-fluid" />
          </Container>
          <hr />
        </div>

        <Nav className="flex-column pt-2">
          {/*<p className="ml-3">MENU</p> */}
          <Nav.Item className="nav-link">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/home"
              role="button"
            >
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              Home
            </NavLink>
          </Nav.Item>
          <Nav.Item className="nav-link">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/user"
              role="button"
            >
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              My profile
            </NavLink>
          </Nav.Item>
          <SubMenuLogs title="Logs" icon={faDatabase} />
          <Nav.Item className="nav-link">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/alerts"
              role="button"
            >
              <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
              Alerts
            </NavLink>
          </Nav.Item>

          <Nav.Item className="nav-link">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/documentation"
              role="button"
            >
              <FontAwesomeIcon icon={faBook} className="mr-2" />
              Documentation
            </NavLink>
          </Nav.Item>

          {parseInt(getAuth().level) === 0 && <AdminSubMenu />}
        </Nav>
      </div>
    );
  }
}

export default SideBar;
