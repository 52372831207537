import React, { Component } from "react";
import UserService from "../../services/user-service";
import { isEmpty } from "../../libs/methods";
import { withRouter, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { Breadcrumb } from "react-bootstrap";

import Layout from "../../components/layout";
import Loading from "../../components/loading";
import Form from "./form";

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      showAlert: false,
      alertText: "",
      alertSeverity: "info", // error, warning, info, success
      userData: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = (e) => {
    this.setState({ userData: null, loading: true });
    UserService.me()
      .then(([response, code]) => {
        this.setState({ userData: response });
      })
      .catch((error) => {});

    this.setState({ loading: false });
  };

  handleOnChange = (e) => {
    const { userData } = this.state;
    const { value, name } = e.target;
    this.setState({
      userData: {
        ...userData,
        [name]: value,
      },
    });
  };

  render() {
    return (
      <Layout>
        <Breadcrumb>
          <NavLink
            exact={true}
            activeClassName="active"
            to="/user"
            role="button"
          >
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            My profile
          </NavLink>
        </Breadcrumb>
        <hr />

        {(isEmpty(this.state.userData) || this.state.loading) && <Loading />}
        {!isEmpty(this.state.userData) && <Form data={this.state.userData} />}
      </Layout>
    );
  }
}

export default withRouter(UserDetail);
