import React from 'react';
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";

const UserStatus = (props) => {
    const {status} = props;
    let result;

    switch (status) {
        case 0:   // Block
            result = <FontAwesomeIcon icon={faExclamation} className="mr-2" />;
            break;
        case 1:  // Active
            result = <FontAwesomeIcon icon={faCheck} className="mr-2" />;
            break;
        default :   // Active
            result = <FontAwesomeIcon icon={faCheck} className="mr-2" />;
            break;
    }
    return <>{ result }</>;
}

export default UserStatus;
