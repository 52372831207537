import axios from "axios";
import request from "./api-service";
function checkWsHealth(service) {
  return new Promise((resolve, reject) => {
    axios
      .get(service)
      .then((response) => {
        resolve(response.status);
      })
      .catch((error) => {
        resolve(false);
      });
  });
}

function checkMonitorHealth(env) {
  let baseurl = `health/monitor/${env}`;
  return request({
    url: baseurl,
    method: "GET",
  });
}

function checkVideosHealth(service) {
  let baseurl = "health/services/svcXcode";
  if (service !== "svcXcode") baseurl = "health/services/svcUpload";

  return request({
    url: baseurl,
    method: "GET",
  });
}

function getWsInstance(env) {
  let baseurl = `admin/websockets/instance/${env}`;
  return request({
    url: baseurl,
    method: "GET",
  });
}

function getDatabaseBackup(env) {
  let baseurl = `health/dbbackupstatus/${env}`;
  return request({
    url: baseurl,
    method: "GET",
  });
}

function rebootWs(env, code) {
  return request({
    url: `admin/websockets/reboot`,
    method: "POST",
    data: { env, code },
  });
}

const HealthService = {
  checkWsHealth,
  checkVideosHealth,
  checkMonitorHealth,
  getWsInstance,
  rebootWs,
  getDatabaseBackup,
};

export default HealthService;
