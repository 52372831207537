import React from "react";

const Products = (props) => {
  const currentColor = props.color ? props.color : "#000";
  const width = props.width ? props.width : 24;
  const height = props.height ? props.height : 24;
  return (
    <svg
      enable-background="new 0 0 48 48"
      id="Layer_3"
      version="1.1"
      viewBox="0 0 48 48"
      width={width}
      height={height}
      fill={currentColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <g>
        <polygon
          fill="#241F20"
          points="21.552,26.021 21.573,26.016 21.573,26.021 41.459,26.021 41.459,26.015 41.481,26.021    45.771,10.016 17.264,10.016  "
        />
        <polygon
          fill="#241F20"
          points="18.646,30 11.21,2.707 0,2.707 0,6.75 8.166,6.75 15.59,34 15.614,33.993 15.614,34 48,34 48,30     "
        />
        <circle cx="41.771" cy="40" fill="#241F20" r="4" />
        <circle cx="19.614" cy="40" fill="#241F20" r="4" />
      </g>
    </svg>
  );
};

export default Products;
