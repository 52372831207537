import ReactJson from "react-json-view";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import { calculateTime } from "../../libs/methods";
const AlertToolTip = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} animation={true}>
      <Modal.Header
        closeButton
        className={classNames({
          "bg-danger": props.data.status === 0,
          "bg-success": props.data.status === 1,
        })}
      >
        <div
          className={classNames("modal-title", {
            "text-white": props.data.status === 0,
          })}
        >
          <b>Alert details - {props.data.name}</b>
        </div>
      </Modal.Header>
      <div className="modal-body">
        <div className="row">
          <div className="col ml-3">
            Last valid condition seen: <b>{props.data.lastSeen}</b>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col ml-3">
            Dispatch time set to:{" "}
            <b>{calculateTime(props.data.Dispach_Time)}</b>
          </div>
        </div>
        <div className="row small  mt-2">
          <div className="col-3 ml-3">Open date</div>
          <div className="col">{props.data.opened}</div>
        </div>
        {props.data.closed !== props.data.opened && (
          <div className="row small  mt-2">
            <div className="col-3 ml-3">Open date</div>
            <div className="col">{props.data.opened}</div>
          </div>
        )}
        <div className="row small mt-2">
          <div className="col-3 ml-3">message</div>
          <div className="col">{props.data.message}</div>
        </div>
        {props.data.extraData && (
          <div className="row small mt-2 mb-2">
            <div className="col-3 ml-3">extraData</div>
            <div className="col">
              <ReactJson displayDataTypes={false} src={props.data.extraData} />;
            </div>
          </div>
        )}
        <div className="row small mt-2">
          <div className="col ml-3">
            ID: <b>{props.data.ID}</b>
          </div>
        </div>
        <div className="row small">
          <div className="col ml-3">
            Dispatch id: <b>{props.data.dispatch_id}</b>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AlertToolTip;
