//style
import "../index.scss";

const LogDate = (props) => {
  let day = "";
  let hour = "";
  if (props.item.fecha) {
    day = props.item.fecha.split(" ")[1];
    hour = props.item.fecha.split(" ")[0];
  }
  return (
    <div>
      {hour} - <span className="date">{day}</span>
    </div>
  );
};

export default LogDate;
