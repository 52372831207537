import React from "react";
import { useHistory } from "react-router-dom";
import Detail from "../../components/detail";
import Layout from "../../components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
const LogDetail = (props) => {
  const last = props.location.pathname.split("/");
  const bucket = last[2];
  const id = last[3];
  let history = useHistory();

  return (
    <Layout>
      <nav aria-label="breadcrumb mb-5">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={faDatabase} className="mr-2" />
              {bucket}
            </a>
          </li>
        </ol>
      </nav>
      <div className="col-10">
        {bucket && <Detail bucket={bucket} id={id} />}
      </div>
    </Layout>
  );
};

export default LogDetail;
